import React, { useState, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import {
  FaCamera,
  FaImage,
  FaObjectGroup,
  FaMagic,
  FaUpload,
} from 'react-icons/fa'

// New Dynamic Island Component
const DynamicIsland = ({ tools, selectedTool, onSelectTool }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const variants = {
    collapsed: {
      width: '250px',
      height: '50px',
      borderRadius: '25px',
    },
    expanded: {
      width: '600px',
      height: '80px',
      borderRadius: '20px',
    },
  }

  return (
    <motion.div
      className="fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50"
      initial="collapsed"
      animate={isExpanded ? 'expanded' : 'collapsed'}
      variants={variants}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <motion.div
        className="w-full h-full overflow-hidden"
        style={{ 
          borderRadius: 'inherit',
          backgroundColor: 'rgba(75, 85, 99, 0.80)',  // Updated to match DashboardResearch
          backdropFilter: 'blur-lg',
        }}
      >
        <div className="absolute inset-0 flex items-center justify-center">
          {/* Collapsed view */}
          <div
            className={`absolute inset-0 flex items-center justify-center transition-all duration-300 ${
              isExpanded ? 'opacity-0' : 'opacity-100'
            }`}
          >
            <div className="flex items-center space-x-4">
              {tools.map((tool) => (
                <motion.div
                  key={tool.id}
                  className={`cursor-pointer p-2.5 rounded-full transition-colors
                    ${selectedTool === tool.id 
                      ? 'bg-white/20 text-white' 
                      : 'text-gray-200 hover:bg-white/20 hover:text-white'}`}
                  onClick={() => onSelectTool(tool.id)}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="w-5 h-5 flex items-center justify-center">
                    {tool.icon}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Expanded view */}
          <div
            className={`absolute inset-0 flex items-center justify-center transition-opacity duration-200 ${
              isExpanded ? 'opacity-100' : 'opacity-0 pointer-events-none'
            }`}
          >
            <div className="grid grid-cols-4 gap-2 w-full px-4">
              {tools.map((tool) => (
                <motion.div
                  key={tool.id}
                  className={`cursor-pointer px-2 py-1.5 rounded-lg text-center
                    ${selectedTool === tool.id 
                      ? 'bg-white/20 text-white' 
                      : 'text-gray-200 hover:bg-white/20 hover:text-white'}`}
                  onClick={() => onSelectTool(tool.id)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="flex flex-col items-center">
                    <div className="w-5 h-5 flex items-center justify-center">
                      {tool.icon}
                    </div>
                    <span className="text-xs font-medium whitespace-nowrap mt-1">
                      {tool.name}
                    </span>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}

// Add this new component for video upload
const VideoUploadArea = ({ onVideoUpload }) => {
  const [dragActive, setDragActive] = useState(false)
  const videoInputRef = useRef(null)

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0])
    }
  }

  const handleFiles = (file) => {
    if (file.type.startsWith('video/')) {
      onVideoUpload(file)
    } else {
      alert('Please upload a video file')
    }
  }

  return (
    <div
      className={`h-full w-full border-2 ${
        dragActive ? 'border-blue-500' : 'border-dashed border-gray-200'
      } rounded-xl bg-gray-50 flex items-center justify-center transition-colors duration-200`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <input
        ref={videoInputRef}
        type="file"
        className="hidden"
        accept="video/*"
        onChange={(e) => e.target.files?.[0] && handleFiles(e.target.files[0])}
      />

      <div className="flex flex-col items-center justify-center p-6">
        <i className="fas fa-video text-4xl text-gray-400 mb-4"></i>
        <p className="text-gray-500 text-center">Upload video feed</p>
        <p className="text-sm text-gray-400 mt-2 text-center">
          Drag and drop or click to upload
        </p>
        <button
          onClick={() => videoInputRef.current?.click()}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Select Video
        </button>
      </div>
    </div>
  )
}

// First, add this sample data structure near the top of the file
const sampleAnalysisData = {
  panels: [
    {
      id: 'environment',
      title: 'Environment Analysis',
      icon: 'fas fa-globe-americas',
      type: 'grid',
      columns: 2,
      items: [
        { label: 'Road Type', value: 'Highway' },
        { label: 'Weather', value: 'Clear' },
        { label: 'Time of Day', value: 'Daytime' },
        { label: 'Road Surface', value: 'Dry' },
      ],
    },
    {
      id: 'traffic',
      title: 'Traffic Objects',
      icon: 'fas fa-car',
      type: 'status',
      items: [
        {
          label: 'Vehicles',
          value: '4',
          detail: '(2 cars, 1 truck, 1 bike)',
          status: 'green',
        },
        {
          label: 'Pedestrians',
          value: '2',
          detail: '(1 walking, 1 stationary)',
          status: 'yellow',
        },
        {
          label: 'Traffic Signs',
          value: '3',
          detail: '(Speed limit, Stop, Yield)',
          status: 'blue',
        },
      ],
    },
    {
      id: 'proximity',
      title: 'Proximity Analysis',
      icon: 'fas fa-ruler',
      type: 'metrics',
      items: [
        {
          label: 'Front Vehicle',
          value: '12.5m',
          trend: { value: '-0.5 m/s', status: 'danger' },
        },
        {
          label: 'Rear Vehicle',
          value: '20.3m',
          trend: { value: '+0.2 m/s', status: 'success' },
        },
        {
          label: 'Safe Distance Status',
          value: 'Maintained',
          status: 'success',
        },
      ],
    },
  ],
}

// Add this new component for rendering analysis panels
const AnalysisPanel = ({ data = sampleAnalysisData }) => {
  const renderPanelContent = (panel) => {
    switch (panel.type) {
      case 'grid':
        return (
          <div className={`grid grid-cols-${panel.columns} gap-4`}>
            {panel.items.map((item, idx) => (
              <div key={idx} className="space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">{item.label}</span>
                  <span className="text-sm font-medium">{item.value}</span>
                </div>
              </div>
            ))}
          </div>
        )

      case 'status':
        return (
          <div className="space-y-3">
            {panel.items.map((item, idx) => (
              <div key={idx} className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div
                    className={`w-2 h-2 rounded-full bg-${item.status}-500`}
                  ></div>
                  <span className="text-sm text-gray-600">{item.label}</span>
                </div>
                <div className="text-right">
                  <div className="text-sm font-medium">{item.value}</div>
                  {item.detail && (
                    <div className="text-xs text-gray-500">{item.detail}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )

      case 'metrics':
        return (
          <div className="space-y-3">
            {panel.items.map((item, idx) => (
              <div key={idx} className="flex justify-between items-center">
                <span className="text-sm text-gray-600">{item.label}</span>
                <div className="text-right">
                  <div className="text-sm font-medium">{item.value}</div>
                  {item.trend && (
                    <div
                      className={`text-xs text-${item.trend.status === 'danger' ? 'red' : 'green'}-500`}
                    >
                      {item.trend.value}
                    </div>
                  )}
                  {item.status && (
                    <div
                      className={`text-xs text-${item.status === 'success' ? 'green' : 'red'}-500`}
                    >
                      {item.value}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )

      default:
        return null
    }
  }

  return (
    <div className="h-full border-2 border-gray-200 rounded-xl bg-gray-50 p-4 overflow-auto">
      <div className="space-y-4">
        {data.panels.map((panel) => (
          <div key={panel.id} className="bg-white rounded-lg p-4 shadow-sm">
            <h4 className="text-sm font-medium text-gray-700 mb-3 flex items-center">
              <i className={`${panel.icon} mr-2`}></i>
              {panel.title}
            </h4>
            {renderPanelContent(panel)}
          </div>
        ))}
      </div>
    </div>
  )
}

// Add this sample data structure near the top where other sample data is defined
const sampleDetectionData = {
  tabs: [
    {
      id: 'people',
      label: 'People',
      count: 2,
      items: [
        {
          id: 1,
          thumbnail: null, // URL for thumbnail
          title: 'Person #1',
          confidence: 98,
          details: [
            { icon: 'fas fa-clock', text: 'First seen: 2s ago' },
            { icon: 'fas fa-map-marker-alt', text: 'Location: Frame center' },
            { icon: 'fas fa-tag', text: 'Status: Walking' },
          ],
        },
        {
          id: 2,
          thumbnail: null,
          title: 'Person #2',
          confidence: 95,
          details: [
            { icon: 'fas fa-clock', text: 'First seen: 5s ago' },
            { icon: 'fas fa-map-marker-alt', text: 'Location: Left side' },
            { icon: 'fas fa-tag', text: 'Status: Standing' },
          ],
        },
      ],
    },
    {
      id: 'objects',
      label: 'Objects',
      count: 2,
      items: [
        {
          id: 1,
          thumbnail: null,
          title: 'Object #1',
          confidence: 95,
          details: [
            { icon: 'fas fa-clock', text: 'First seen: 5s ago' },
            { icon: 'fas fa-map-marker-alt', text: 'Location: Right side' },
            { icon: 'fas fa-tag', text: 'Category: Electronics' },
          ],
        },
        {
          id: 2,
          thumbnail: null,
          title: 'Object #2',
          confidence: 92,
          details: [
            { icon: 'fas fa-clock', text: 'First seen: 8s ago' },
            { icon: 'fas fa-map-marker-alt', text: 'Location: Bottom center' },
            { icon: 'fas fa-tag', text: 'Category: Furniture' },
          ],
        },
      ],
    },
  ],
}

// Add this new component for the detection results
const DetectionResults = ({ data = sampleDetectionData }) => {
  const [activeTab, setActiveTab] = useState(data.tabs[0].id)

  const currentTab = data.tabs.find((tab) => tab.id === activeTab)

  return (
    <div className="h-[500px] flex flex-col border-2 border-gray-200 rounded-xl bg-gray-50">
      {/* Tabs */}
      <div className="flex border-b border-gray-200">
        {data.tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`px-4 py-2 text-sm font-medium transition-colors
              ${
                activeTab === tab.id
                  ? 'text-blue-600 border-b-2 border-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
          >
            {tab.label} ({tab.count})
          </button>
        ))}
      </div>

      {/* Results List */}
      <div className="flex-1 overflow-auto p-4">
        <div className="space-y-4">
          {currentTab?.items.map((item) => (
            <div
              key={item.id}
              className="bg-white rounded-lg p-3 shadow-sm border border-gray-100"
            >
              <div className="flex items-start space-x-3">
                <div className="w-16 h-16 bg-gray-200 rounded-lg flex-shrink-0">
                  {item.thumbnail && (
                    <img
                      src={item.thumbnail}
                      alt={item.title}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">
                      {item.title}
                    </p>
                    <span className="text-xs text-green-500 font-medium">
                      {item.confidence}%
                    </span>
                  </div>
                  <div className="mt-1 space-y-1">
                    {item.details.map((detail, idx) => (
                      <div
                        key={idx}
                        className="flex items-center text-xs text-gray-500"
                      >
                        <i className={`${detail.icon} w-4`}></i>
                        <span>{detail.text}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Summary Footer */}
      <div className="border-t border-gray-200 p-4">
        <div className="flex items-center justify-between text-sm">
          <span className="text-gray-600">Total {currentTab?.label}:</span>
          <span className="font-medium">{currentTab?.count} items</span>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <button className="px-3 py-1.5 text-xs bg-gray-100 text-gray-700 rounded hover:bg-gray-200 transition-colors">
            Clear All
          </button>
          <button className="px-3 py-1.5 text-xs bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">
            Export Results
          </button>
        </div>
      </div>
    </div>
  )
}

// Update the main component to use the new DynamicIsland
const DashboardVision = () => {
  const [selectedTool, setSelectedTool] = useState('segment-anything')
  const [dragActive, setDragActive] = useState(false)
  const [uploadedImages, setUploadedImages] = useState({
    'segment-anything': null,
    'image-analysis': null,
    'diffusion-generation': null,
  })
  const [activeTab, setActiveTab] = useState('people')

  const visionTools = [
    {
      id: 'segment-anything',
      name: 'Segment Anything',
      icon: <FaObjectGroup className="w-6 h-6" />,
      description:
        'Segment anything in an image using the Segment Anything Model (SAM 2)',
    },
    {
      id: 'video-analysis',
      name: 'Autonomous Vision',
      icon: <FaImage className="w-6 h-6" />,
      description:
        'Advanced autonomous vehicle vision system with real-time object detection and environment analysis',
    },
    {
      id: 'real-time-identifier',
      name: 'Real-time Identifier',
      icon: <FaCamera className="w-6 h-6" />,
      description:
        'Advanced face detection and tracking with work-life status analysis, powered by DSFD model and continuous learning pipeline',
    },
    {
      id: 'diffusion-generation',
      name: 'Diffusion Generation',
      icon: <FaMagic className="w-6 h-6" />,
      description: 'Generate images using diffusion models',
    },
  ]

  const fileInputRefs = {
    'segment-anything': useRef(null),
    'image-analysis': useRef(null),
    'diffusion-generation': useRef(null),
  }

  const handleDrag = (e, tool) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e, tool) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0], tool)
    }
  }

  const handleFileInput = (e, tool) => {
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0], tool)
    }
  }

  const handleFiles = (file, tool) => {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader()
      reader.onload = (e) => {
        setUploadedImages((prev) => ({
          ...prev,
          [tool]: e.target.result,
        }))
      }
      reader.readAsDataURL(file)
    } else {
      alert('Please upload an image file')
    }
  }

  const clearImage = (tool) => {
    setUploadedImages((prev) => ({
      ...prev,
      [tool]: null,
    }))
  }

  // Reusable upload area component
  const ImageUploadArea = ({ tool, text }) => (
    <div
      className={`h-full w-full border-2 ${
        dragActive ? 'border-blue-500' : 'border-dashed border-gray-200'
      } 
        rounded-xl bg-gray-50 flex items-center justify-center transition-colors duration-200`}
      onDragEnter={(e) => handleDrag(e, tool)}
      onDragLeave={(e) => handleDrag(e, tool)}
      onDragOver={(e) => handleDrag(e, tool)}
      onDrop={(e) => handleDrop(e, tool)}
    >
      <input
        ref={fileInputRefs[tool]}
        type="file"
        className="hidden"
        accept="image/*"
        onChange={(e) => handleFileInput(e, tool)}
      />

      {uploadedImages[tool] ? (
        <div className="h-full w-full">
          <img
            src={uploadedImages[tool]}
            alt="Uploaded"
            className="w-full h-full object-contain rounded-lg"
          />
          <button
            onClick={() => clearImage(tool)}
            className="absolute top-2 right-2 p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
          >
            ×
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center p-6">
          <FaUpload className="h-12 w-12 text-gray-400 mb-4" />
          <p className="text-gray-500 text-center">{text}</p>
          <p className="text-sm text-gray-400 mt-2 text-center">
            Drag and drop or click to upload
          </p>
          <button
            onClick={() => fileInputRefs[tool].current?.click()}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Select File
          </button>
        </div>
      )}
    </div>
  )

  // Update the segment-anything section
  const segmentAnythingContent = (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="h-96 flex flex-col space-y-4">
        <ImageUploadArea
          tool="segment-anything"
          text="Upload an image for segmentation"
        />
      </div>
      <div className="h-96 flex flex-col space-y-4 border-2 border-gray-200 rounded-xl bg-gray-50 p-4">
        <div className="flex-1 overflow-auto">
          {/* Segmentation masks will be displayed here */}
        </div>
      </div>
    </div>
  )

  // Update the video-analysis section with more advanced features
  const videoAnalysisContent = (
    <div className="flex flex-col space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Main Video Feed Column */}
        <div className="md:col-span-2 space-y-4">
          {/* Primary Video Feed - Reduced height */}
          <div className="h-[500px] relative border-2 border-gray-200 rounded-xl bg-gray-50 overflow-hidden">
            {uploadedImages['video-analysis'] ? (
              <div className="relative w-full h-full">
                <img
                  src={uploadedImages['video-analysis']}
                  alt="Main Video Feed"
                  className="w-full h-full object-cover"
                />
                {/* Enhanced Overlay System */}
                <div className="absolute inset-0">
                  {/* Vehicle Detection with Distance */}
                  <div className="absolute top-1/3 left-1/4 w-32 h-16 border-2 border-green-500 rounded-md">
                    <span className="absolute -top-6 left-0 bg-green-500 text-white px-2 py-1 text-xs rounded">
                      Vehicle: 98% - 12.5m
                    </span>
                    <div className="absolute -bottom-6 left-0 bg-blue-500 text-white px-2 py-1 text-xs rounded">
                      Speed: 35mph
                    </div>
                  </div>

                  {/* Enhanced Lane Detection */}
                  <div className="absolute bottom-0 left-0 w-full h-32">
                    <div className="relative h-full">
                      {/* Dynamic Lane Lines */}
                      <div className="absolute bottom-0 w-full flex justify-between px-20">
                        <div className="w-1 h-full bg-yellow-400/50 transform skew-x-12"></div>
                        <div className="w-1 h-full bg-white/50"></div>
                        <div className="w-1 h-full bg-yellow-400/50 transform -skew-x-12"></div>
                      </div>
                      {/* Lane Departure Warning Zone */}
                      <div className="absolute bottom-0 w-full h-4 bg-gradient-to-t from-red-500/20 to-transparent"></div>
                    </div>
                  </div>

                  {/* Advanced HUD */}
                  <div className="absolute top-4 right-4 space-y-2">
                    {/* Speed Display */}
                    <div className="bg-black/70 text-white px-4 py-2 rounded-lg">
                      <div className="text-2xl font-bold">60</div>
                      <div className="text-xs">MPH</div>
                    </div>
                    {/* Navigation Next Turn */}
                    <div className="bg-black/70 text-white px-4 py-2 rounded-lg">
                      <div className="text-sm">Next Turn</div>
                      <div className="text-lg">→ 0.5mi</div>
                    </div>
                  </div>

                  {/* Object Distance Markers */}
                  <div className="absolute inset-0">
                    <div className="grid grid-cols-4 h-full">
                      {[...Array(4)].map((_, i) => (
                        <div
                          key={i}
                          className="border-t border-white/20 self-center"
                          style={{
                            marginTop: `${i * 20}px`,
                          }}
                        >
                          <span className="text-xs text-white bg-black/50 px-1 rounded">
                            {(i + 1) * 5}m
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <ImageUploadArea
                tool="video-analysis"
                text="Upload main video feed"
              />
            )}
          </div>

          {/* Additional Video Streams Grid - Reduced height */}
          <div className="grid grid-cols-3 gap-4 h-[180px]">
            {/* Depth Map View */}
            <div className="relative border-2 border-gray-200 rounded-xl bg-gray-50 overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-red-500/20">
                <div className="absolute top-2 left-2 bg-black/70 text-white text-xs px-2 py-1 rounded">
                  Depth Map
                </div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-full h-2/3 mx-4 bg-gradient-to-r from-blue-500/10 via-blue-300/20 to-red-400/30 rounded"></div>
                </div>
              </div>
            </div>

            {/* Semantic Segmentation View */}
            <div className="relative border-2 border-gray-200 rounded-xl bg-gray-50 overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-r from-green-500/20 to-purple-500/20">
                <div className="absolute top-2 left-2 bg-black/70 text-white text-xs px-2 py-1 rounded">
                  Segmentation
                </div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-full h-2/3 mx-4 flex space-x-1">
                    <div className="flex-1 bg-green-500/20 rounded"></div>
                    <div className="flex-1 bg-blue-500/20 rounded"></div>
                    <div className="flex-1 bg-purple-500/20 rounded"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* Bird's Eye View */}
            <div className="relative border-2 border-gray-200 rounded-xl bg-gray-50 overflow-hidden">
              <div className="absolute inset-0 bg-gray-900/10">
                <div className="absolute top-2 left-2 bg-black/70 text-white text-xs px-2 py-1 rounded">
                  Bird's Eye View
                </div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-4/5 h-2/3 relative">
                    <div className="absolute inset-0 border-2 border-white/30 rounded transform perspective-800 rotateX-60">
                      <div className="absolute inset-x-0 top-1/2 border-t-2 border-white/30"></div>
                      <div className="absolute inset-y-0 left-1/4 border-l-2 border-white/30"></div>
                      <div className="absolute inset-y-0 right-1/4 border-r-2 border-white/30"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Analysis Panel - Right Column - Adjusted height to match */}
        <div className="h-[700px]">
          <AnalysisPanel />
        </div>
      </div>

      {/* Control Panel remains the same */}
      <div className="bg-white rounded-xl p-6 border border-gray-200">
        <div className="flex items-center justify-between space-x-6">
          {/* Left Actions */}
          <div className="flex items-center space-x-2">
            <button className="flex items-center justify-center space-x-2 px-4 py-2.5 bg-gray-50 hover:bg-gray-100 text-gray-700 rounded-lg transition-colors duration-200">
              <i className="fas fa-redo text-sm"></i>
              <span className="text-sm">Reset</span>
            </button>
            <button className="w-10 h-10 bg-gray-50 hover:bg-gray-100 text-gray-700 rounded-lg transition-colors duration-200 flex items-center justify-center">
              <i className="fas fa-th-large text-sm"></i>
            </button>
          </div>

          {/* Center Primary Actions */}
          <div className="flex items-center space-x-4">
            <button className="px-6 py-3 bg-gradient-to-r from-blue-300 to-blue-500 text-white rounded-xl hover:from-blue-400 hover:to-blue-600 transition-all duration-200 shadow-sm hover:shadow flex items-center justify-center space-x-2 min-w-[200px]">
              <i className="fas fa-play text-sm"></i>
              <span>Start</span>
            </button>
            <button className="w-12 h-12 bg-red-400 hover:bg-red-500 text-white rounded-xl transition-all duration-200 shadow-sm hover:shadow flex items-center justify-center">
              <i className="fas fa-circle text-sm"></i>
            </button>
          </div>

          {/* Right Actions */}
          <div className="flex items-center space-x-2">
            <button className="flex items-center justify-center space-x-2 px-4 py-2.5 bg-gray-50 hover:bg-gray-100 text-gray-700 rounded-lg transition-colors duration-200">
              <i className="fas fa-download text-sm"></i>
              <span className="text-sm">Export</span>
            </button>
            <button className="w-10 h-10 bg-gray-50 hover:bg-gray-100 text-gray-700 rounded-lg transition-colors duration-200 flex items-center justify-center">
              <i className="fas fa-cog text-sm"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  // Update where these sections are rendered in your return statement
  return (
    <div className="h-full relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-bold text-gray-900">AI Vision Lab</h1>
            <p className="mt-2 text-sm text-gray-600">
              Explore various computer vision applications and experiments
            </p>
          </div>
        </div>

        <div className="space-y-8">
          {/* Remove the grid of tools and add the DynamicIsland */}
          <DynamicIsland
            tools={visionTools}
            selectedTool={selectedTool}
            onSelectTool={setSelectedTool}
          />

          {selectedTool === 'segment-anything' && (
            <div className="bg-white rounded-2xl shadow-sm p-6 border border-gray-100">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Segment Anything
              </h2>
              <div className="space-y-4">{segmentAnythingContent}</div>
            </div>
          )}

          {selectedTool === 'video-analysis' && (
            <div className="bg-white rounded-2xl shadow-sm p-6 border border-gray-100">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Autonomous Vision System
              </h2>
              <div className="space-y-4">{videoAnalysisContent}</div>
            </div>
          )}

          {selectedTool === 'diffusion-generation' && (
            <div className="bg-white rounded-2xl shadow-sm p-6 border border-gray-100">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Diffusion Generation
              </h2>
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-4">
                    <div className="flex flex-col space-y-2">
                      <label className="text-sm font-medium text-gray-700">
                        Prompt
                      </label>
                      <textarea
                        className="w-full h-32 p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder="Describe the image you want to generate..."
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="text-sm font-medium text-gray-700">
                        Settings
                      </label>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-1">
                          <label className="text-xs text-gray-500">Steps</label>
                          <input
                            type="number"
                            className="w-full p-2 border border-gray-200 rounded-lg"
                            defaultValue={50}
                          />
                        </div>
                        <div className="space-y-1">
                          <label className="text-xs text-gray-500">
                            Guidance Scale
                          </label>
                          <input
                            type="number"
                            className="w-full p-2 border border-gray-200 rounded-lg"
                            defaultValue={7.5}
                            step={0.1}
                          />
                        </div>
                      </div>
                    </div>
                    <button className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                      Generate Image
                    </button>
                  </div>
                  <div className="h-96 flex flex-col space-y-4 border-2 border-gray-200 rounded-xl bg-gray-50 p-4">
                    <div className="flex-1 flex items-center justify-center">
                      <p className="text-gray-500">Image will appear here</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedTool === 'real-time-identifier' && (
            <div className="bg-white rounded-2xl shadow-sm p-6 border border-gray-100">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Real-time Identifier
              </h2>
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {/* Main Video Feed */}
                  <div className="md:col-span-2 space-y-4">
                    <div className="h-[500px] relative border-2 border-gray-200 rounded-xl bg-gray-50 overflow-hidden">
                      <VideoUploadArea
                        onVideoUpload={(file) => {
                          // Handle video upload here
                          console.log('Video uploaded:', file)
                        }}
                      />
                    </div>
                  </div>

                  {/* Detection Results Panel */}
                  <DetectionResults />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DashboardVision
