import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

const useUIStateManager = (sendMessage) => {
  const location = useLocation()

  const capturePageState = useCallback(() => {
    // Get visible interactive elements
    const interactiveElements = document.querySelectorAll(
      'button, input, select, textarea, a[href], [role="button"]'
    )

    const elements = Array.from(interactiveElements)
      .filter(isElementVisible)
      .map((el) => ({
        type: el.tagName.toLowerCase(),
        id: el.id,
        text: el.textContent?.trim() || el.value,
        placeholder: el.placeholder,
        href: el.href,
        'aria-label': el.getAttribute('aria-label'),
        selector: generateSelector(el),
      }))

    // Send the state update through websocket
    sendMessage({
      type: 'visible_elements',
      path: location.pathname,
      elements: elements,
    })
  }, [sendMessage, location.pathname])

  // Utility to check if element is visible
  const isElementVisible = (el) => {
    const rect = el.getBoundingClientRect()
    return (
      rect.width > 0 &&
      rect.height > 0 &&
      window.getComputedStyle(el).visibility !== 'hidden' &&
      window.getComputedStyle(el).display !== 'none'
    )
  }

  // Generate unique selector for element
  const generateSelector = (el) => {
    if (el.id) return `#${el.id}`
    if (el.placeholder) return `input[placeholder="${el.placeholder}"]`
    if (el.className) {
      const classes = el.className.split(' ').filter((c) => c)
      if (classes.length) return `.${classes.join('.')}`
    }
    if (el.getAttribute('aria-label'))
      return `[aria-label="${el.getAttribute('aria-label')}"]`
    return el.tagName.toLowerCase()
  }

  return {
    capturePageState,
  }
}

export default useUIStateManager
