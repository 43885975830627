import { useEffect, useCallback, useRef } from 'react'
import { getAuth } from 'firebase/auth'
import useUIActions from './useUIActions'
import useUIStateManager from './useUIStateManager'
import useResearchPaperManager from './useResearchPaperManager'
import { getRoutes } from '../utils/routes'

const useWebSocket = (baseUrl, options, conversationId) => {
  const wsRef = useRef(null)
  const optionsRef = useRef(options)
  const connectingRef = useRef(false)
  const reconnectTimeoutRef = useRef(null)
  const mountedRef = useRef(true)
  const previousConversationIdRef = useRef(conversationId)

  const sendMessage = useCallback((message) => {
    if (wsRef.current) {
      wsRef.current.send(JSON.stringify(message))
    } else {
      console.warn('Attempted to send message while disconnected')
    }
  })

  const { handleUIAction } = useUIActions(sendMessage)
  const { capturePageState } = useUIStateManager(sendMessage)
  const { sendResearchPaperContent } = useResearchPaperManager(sendMessage)

  const handleMessage = useCallback(
    async (data) => {
      if (!mountedRef.current) return

      try {
        switch (data.type) {
          case 'stream':
            // Handle streaming chunks
            optionsRef.current.onMessage({
              type: 'stream',
              message: data.message,
            })
            break
          case 'complete':
            // Handle stream completion
            optionsRef.current.onMessage({
              type: 'complete',
              message: data.message,
            })
            break
          case 'history':
            // Transform history messages to match the expected format
            const transformedMessages = data.messages
              .filter((msg) => msg.type !== 'thought')
              .map((msg) => {
                switch (msg.type) {
                  case 'agent_delegation':
                    return {
                      type: 'agent_delegation',
                      content: msg.content,
                      metadata: msg.metadata,
                    }
                  case 'tool_call':
                    return {
                      type: 'tool_call',
                      content: msg.content,
                      tool_calls: msg.tool_calls,
                      metadata: msg.metadata,
                    }
                  default:
                    return {
                      type: msg.role === 'user' ? 'user' : 'bot',
                      content: msg.content,
                    }
                }
              })
            optionsRef.current.onMessage({
              type: 'history',
              messages: transformedMessages,
            })
            break
          case 'agent_delegation':
            optionsRef.current.onMessage({
              type: 'agent_delegation',
              agent_name: data.agent_name,
            })
            break
          case 'tool_call':
            optionsRef.current.onMessage({
              type: 'tool_call',
              tool_calls: data.tool_calls,
            })
            break
          case 'ui_action':
            await handleUIAction(data.ui_action)
            break
          case 'request_ui_state':
            capturePageState()
            break
          case 'request_research_paper_content':
            sendResearchPaperContent()
            break
          default:
            console.warn('Unknown message type:', data.type)
        }
      } catch (error) {
        console.error('Error handling message:', error)
        if (optionsRef.current.onError) {
          optionsRef.current.onError(error)
        }
      }
    },
    [handleUIAction, capturePageState, sendResearchPaperContent]
  )

  const connect = useCallback(async () => {
    if (
      previousConversationIdRef.current === conversationId &&
      wsRef.current?.readyState === WebSocket.OPEN
    ) {
      return
    }

    if (!conversationId) {
      return
    }

    if (connectingRef.current || !mountedRef.current) {
      return
    }

    connectingRef.current = true
    previousConversationIdRef.current = conversationId

    try {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user) {
        console.warn('No authenticated user found')
        return
      }

      // Clear any existing connection
      if (wsRef.current) {
        wsRef.current.close()
        wsRef.current = null
      }

      const token = await user.getIdToken()

      const ws = new WebSocket(`${baseUrl}/${conversationId}?token=${token}`)

      ws.onopen = () => {
        if (!mountedRef.current) {
          ws.close()
          return
        }
        if (optionsRef.current.onOpen) {
          optionsRef.current.onOpen(conversationId)
        }
        // Send available routes to backend
        sendMessage({
          type: 'available_routes',
          routes: getRoutes(),
        })
      }

      ws.onclose = () => {
        if (!mountedRef.current) return

        if (optionsRef.current.onClose) {
          optionsRef.current.onClose()
        }
      }

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data)
          handleMessage(data)
        } catch (error) {
          console.error('WebSocket message parse error:', error)
          if (optionsRef.current.onError) {
            optionsRef.current.onError(error)
          }
        }
      }

      ws.onerror = (error) => {
        if (!mountedRef.current) return

        if (optionsRef.current.onError) {
          optionsRef.current.onError(error)
        }
      }

      wsRef.current = ws
    } catch (error) {
      console.error('[WebSocket] Connection error:', error)
      if (optionsRef.current.onError) {
        optionsRef.current.onError(error)
      }
    } finally {
      if (mountedRef.current) {
        connectingRef.current = false
      }
    }
  }, [baseUrl, conversationId])

  // Update options ref when options change
  useEffect(() => {
    optionsRef.current = options
  }, [options])

  // Modify the useEffect to only trigger on mount and conversationId changes
  useEffect(() => {
    if (!conversationId) {
      return
    }

    mountedRef.current = true
    connect()

    return () => {
      mountedRef.current = false
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current)
      }
      if (wsRef.current) {
        wsRef.current.close(1000, 'Component unmounted')
        wsRef.current = null
      }
    }
  }, [connect])

  return {
    sendMessage,
  }
}

export default useWebSocket
