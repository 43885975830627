import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { loadBlogPosts } from '../../utils/blogLoader'

const BlogPost = () => {
  const { slug } = useParams()
  const navigate = useNavigate()
  const [blog, setBlog] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const posts = await loadBlogPosts()
        const foundBlog = posts.find((b) => b.slug === slug)

        if (!foundBlog) {
          navigate('/blog')
          return
        }

        setBlog(foundBlog)
      } catch (error) {
        console.error('Error loading blog post:', error)
        navigate('/blog')
      } finally {
        setLoading(false)
      }
    }

    fetchBlog()
  }, [slug, navigate])

  if (loading) {
    return (
      <div className="flex-grow flex items-center justify-center bg-gray-100 pt-16">
        <div className="text-gray-600">Loading post...</div>
      </div>
    )
  }

  if (!blog) {
    return null
  }

  return (
    <div className="flex-grow bg-gray-100 pt-16">
      <article className="max-w-2xl mx-auto px-4 py-16">
        <button
          onClick={() => navigate('/blog')}
          className="text-gray-500 hover:text-gray-700 mb-12 flex items-center gap-2"
        >
          ← Back to stories
        </button>

        <header className="mb-8">
          <time className="text-sm text-gray-500">
            {new Date(blog.timestamp).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </time>
          <h1 className="text-4xl font-serif mt-2 mb-4 text-gray-900">
            {blog.title}
          </h1>
        </header>

        <div className="prose prose-lg prose-gray max-w-none">
          <ReactMarkdown
            components={{
              // Style headers
              h1: ({ node, children, ...props }) => (
                <h1 className="text-3xl font-bold mt-8 mb-4" {...props}>
                  {children}
                </h1>
              ),
              h2: ({ node, children, ...props }) => (
                <h2 className="text-2xl font-bold mt-6 mb-3" {...props}>
                  {children}
                </h2>
              ),
              h3: ({ node, children, ...props }) => (
                <h3 className="text-xl font-bold mt-4 mb-2" {...props}>
                  {children}
                </h3>
              ),
              // Style paragraphs
              p: ({ node, ...props }) => (
                <p className="mb-4 leading-relaxed" {...props} />
              ),
              // Style lists
              ul: ({ node, ...props }) => (
                <ul className="list-disc pl-6 mb-4" {...props} />
              ),
              ol: ({ node, ...props }) => (
                <ol className="list-decimal pl-6 mb-4" {...props} />
              ),
              li: ({ node, ...props }) => <li className="mb-1" {...props} />,
              // Style blockquotes
              blockquote: ({ node, ...props }) => (
                <blockquote
                  className="border-l-4 border-gray-200 pl-4 italic my-4"
                  {...props}
                />
              ),
              // Style code blocks
              code: ({ node, inline, ...props }) =>
                inline ? (
                  <code
                    className="bg-gray-100 rounded px-1 py-0.5"
                    {...props}
                  />
                ) : (
                  <code
                    className="block bg-gray-100 p-4 rounded my-4 overflow-auto"
                    {...props}
                  />
                ),
            }}
          >
            {blog.content}
          </ReactMarkdown>
        </div>
      </article>
    </div>
  )
}

export default BlogPost
