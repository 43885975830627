import React from 'react'
import { useUIActionContext } from '../../contexts/UIActionContext'

const ActionFeedback = () => {
  const { actionStatus } = useUIActionContext()

  if (!actionStatus.isExecuting && !actionStatus.error) return null

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {actionStatus.isExecuting && (
        <div className="bg-blue-100 text-blue-800 px-4 py-2 rounded-lg shadow">
          Executing UI action...
        </div>
      )}
      {actionStatus.error && (
        <div className="bg-red-100 text-red-800 px-4 py-2 rounded-lg shadow">
          {actionStatus.error}
        </div>
      )}
    </div>
  )
}

export default ActionFeedback
