import React from 'react'
import {
  LineChart,
  BarChart,
  PieChart,
  MapChart,
  DonutChart,
  ScatterChart,
  HistogramChart,
  BoxplotChart,
  AreaChart,
  BubbleChart,
  TableChart,
  ClusterChart,
} from '../Charts'
import { CHART_STYLES } from '../constants/chartStyles'

const ChartRenderer = ({ type, data, config }) => {
  const commonProps = {
    className: 'transition-all duration-300 ease-in-out rounded-xl p-4 bg-white',
    style: { fontFamily: CHART_STYLES.fontFamily },
  }

  const chartComponents = {
    line: LineChart,
    bar: BarChart,
    pie: PieChart,
    map: MapChart,
    donut: DonutChart,
    scatter: ScatterChart,
    histogram: HistogramChart,
    boxplot: BoxplotChart,
    area: AreaChart,
    bubble: BubbleChart,
    table: TableChart,
    cluster: ClusterChart,
  }

  const ChartComponent = chartComponents[type]
  
  if (!ChartComponent) {
    return <div>Unsupported chart type</div>
  }

  return <ChartComponent data={data} config={config} {...commonProps} />
}

export default ChartRenderer 