import React from 'react'
import ChartRenderer from './ChartRenderer'

const VisualizationSection = ({ content }) => {
  // Group items into rows based on their width
  const rows = content.reduce((acc, item) => {
    const lastRow = acc[acc.length - 1]

    // Always start a new row if:
    // 1. There are no rows yet
    // 2. Current item is full width
    // 3. Previous row has a full width item
    // 4. Last row already has two half-width items
    if (
      !lastRow ||
      item.width === 'full' ||
      lastRow.items[0]?.width === 'full' ||
      lastRow.items.length === 2
    ) {
      acc.push({ items: [item] })
    } else {
      // Only add to existing row if both items are half width
      if (lastRow.items[0]?.width === 'half' && item.width === 'half') {
        lastRow.items.push(item)
      } else {
        // Start new row for any other case
        acc.push({ items: [item] })
      }
    }
    return acc
  }, [])

  return (
    <div className="space-y-6">
      {rows.map((row, rowIndex) => (
        <div
          key={rowIndex}
          className={`grid gap-6 ${
            row.items.length > 1 ? 'grid-cols-1 md:grid-cols-2' : 'grid-cols-1'
          }`}
        >
          {row.items.map((item, itemIndex) => {
            if (item.type === 'visualization') {
              return (
                <div
                  key={itemIndex}
                  className={`bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 p-6 border border-gray-100 ${
                    item.width === 'full' ? 'col-span-full' : ''
                  }`}
                >
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">
                    {item.title}
                  </h3>
                  <ChartRenderer
                    type={item.chartType}
                    data={item.data}
                    config={item.config}
                  />
                </div>
              )
            } else if (item.type === 'summary') {
              return (
                <div
                  key={itemIndex}
                  className={`bg-white rounded-2xl shadow-sm p-6 border border-gray-100 ${
                    item.width === 'full' ? 'col-span-full' : ''
                  }`}
                >
                  <p className="text-gray-600 leading-relaxed">
                    {item.content}
                  </p>
                </div>
              )
            }
            return null
          })}
        </div>
      ))}
    </div>
  )
}

export default VisualizationSection 