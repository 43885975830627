import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { CHART_COLORS, CHART_STYLES } from '../constants/chartStyles'

const HistogramChart = ({ data, config }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" stroke={CHART_STYLES.gridColor} />
        <XAxis
          dataKey={config.xAxis}
          tick={{ fill: CHART_COLORS.neutral }}
          axisLine={{ stroke: CHART_STYLES.gridColor }}
        />
        <YAxis
          tick={{ fill: CHART_COLORS.neutral }}
          axisLine={{ stroke: CHART_STYLES.gridColor }}
        />
        <Tooltip
          contentStyle={{
            backgroundColor: CHART_STYLES.tooltipBackground,
            borderRadius: '0.5rem',
            border: 'none',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
          }}
        />
        <Legend />
        <Bar
          dataKey={config.yAxis}
          fill={CHART_COLORS.primary}
          opacity={0.8}
          barSize={config.barSize || 20}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default HistogramChart 