import React, { useState, useEffect, useCallback } from 'react'
import { API_ENDPOINTS } from '../../config'
import { getAuth } from 'firebase/auth'
import toast from 'react-hot-toast'
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  Panel,
  Position,
  Handle,
} from 'reactflow'
import 'reactflow/dist/style.css'
import MarkdownRenderer from '../shared/MarkdownRenderer'

const MessageItem = ({ message }) => {
  const getMessageColor = (role) => {
    switch (role) {
      case 'user':
        return 'bg-blue-50'
      case 'assistant':
        return 'bg-gray-50'
      case 'system':
        return 'bg-orange-50'
      case 'tool':
        return 'bg-green-50'
      default:
        return 'bg-white'
    }
  }

  const getMessageIcon = (role) => {
    switch (role) {
      case 'user':
        return 'fas fa-user'
      case 'assistant':
        return 'fas fa-robot'
      case 'system':
        return 'fas fa-cog'
      case 'tool':
        return 'fas fa-tools'
      default:
        return 'fas fa-comment'
    }
  }

  const formatTime = (timestamp) => {
    if (!timestamp) return ''
    const date = new Date(timestamp)
    return new Intl.DateTimeFormat('default', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      month: 'short',
      day: 'numeric',
    }).format(date)
  }

  const getAgentBadgeColor = (agentName) => {
    switch (agentName) {
      case 'Coordinator Agent':
        return 'bg-purple-100 text-purple-800'
      case 'Dashboard Agent':
        return 'bg-green-100 text-green-800'
      default:
        return 'bg-gray-100 text-gray-800'
    }
  }

  const renderToolCalls = (toolCalls) => {
    if (!toolCalls) return null

    // Parse the tool calls if it's a string
    let parsedToolCalls = toolCalls
    if (typeof toolCalls === 'string') {
      try {
        parsedToolCalls = JSON.parse(toolCalls)
      } catch (e) {
        console.error('Error parsing tool calls:', e)
        return null
      }
    }

    // Ensure it's an array and has items
    if (!Array.isArray(parsedToolCalls) || parsedToolCalls.length === 0)
      return null

    return (
      <div className="mt-2 space-y-2">
        {parsedToolCalls.map((tool, index) => (
          <div
            key={index}
            className="flex items-start space-x-2 text-sm bg-gray-50 rounded p-2"
          >
            <i className="fas fa-tools mt-1 text-gray-500"></i>
            <div>
              <span className="font-medium">{tool.type}</span>
              <pre className="mt-1 text-xs bg-gray-100 p-2 rounded">
                {JSON.stringify(tool.params, null, 2)}
              </pre>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={`${getMessageColor(message.role)} rounded-lg p-4 mb-2`}>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <i className={`${getMessageIcon(message.role)} text-gray-500`}></i>
          <span className="text-sm font-medium text-gray-700 capitalize">
            {message.role}
          </span>
          {message.agent_name && (
            <span
              className={`px-2 py-1 text-xs font-medium rounded-full ${getAgentBadgeColor(message.agent_name)}`}
            >
              {message.agent_name}
            </span>
          )}
          {message.message_type && (
            <span className="px-2 py-1 text-xs font-medium text-gray-500 bg-gray-100 rounded-full">
              {message.message_type}
            </span>
          )}
        </div>
        <span className="text-xs text-gray-400">
          {formatTime(message.created_at)}
        </span>
      </div>
      <MarkdownRenderer content={message.content} />
      {renderToolCalls(message.tool_calls)}
      {message.metadata && (
        <div className="mt-2 p-2 bg-gray-100 rounded text-xs font-mono text-gray-600">
          {typeof message.metadata === 'string'
            ? message.metadata
            : JSON.stringify(message.metadata, null, 2)}
        </div>
      )}
    </div>
  )
}

// Update the FlowNode component to show message statistics
const FlowNode = ({ data }) => {
  const getNodeStyle = () => {
    if (data.type === 'user') {
      return 'bg-blue-50 border-blue-200'
    }
    // Add different colors for different agent types
    switch (data.label) {
      // case 'Coordinator Agent':
      //   return 'bg-purple-50 border-purple-200';
      // case 'Dashboard Agent':
      //   return 'bg-green-50 border-green-200';
      default:
        return 'bg-white border-gray-200'
    }
  }

  const getIcon = () => {
    if (data.type === 'user') {
      return 'fas fa-user'
    }
    switch (data.label) {
      case 'Coordinator Agent':
        return 'fas fa-filter'
      case 'Chat Agent':
        return 'fas fa-comments'
      case 'Dashboard Agent':
        return 'fas fa-chart-bar'
      default:
        return 'fas fa-robot'
    }
  }

  // Calculate message statistics
  const getMessageStats = () => {
    if (!data.messages) return {}

    return data.messages.reduce(
      (stats, msg) => {
        // Count message types
        if (msg.message_type) {
          stats.types[msg.message_type] =
            (stats.types[msg.message_type] || 0) + 1
        }
        // Count tool calls
        if (msg.tool_calls) {
          const toolCalls =
            typeof msg.tool_calls === 'string'
              ? JSON.parse(msg.tool_calls)
              : msg.tool_calls
          stats.toolCalls += toolCalls.length
        }
        return stats
      },
      { types: {}, toolCalls: 0 }
    )
  }

  const stats = getMessageStats()

  return (
    <>
      <Handle
        type="source"
        position={Position.Right}
        id="right"
        style={{ background: '#555' }}
      />

      <div
        className={`px-4 py-3 shadow-lg rounded-md border-2 
          hover:shadow-xl transition-all duration-200 cursor-pointer
          transform hover:-translate-y-0.5 ${getNodeStyle()}`}
        style={{ minWidth: '200px', maxWidth: '280px' }}
      >
        <div className="flex flex-col gap-2">
          {/* Header */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <i className={`${getIcon()} text-gray-600`}></i>
              <div className="font-bold text-gray-800">{data.label}</div>
            </div>
            <div className="text-xs font-medium bg-gray-200 text-gray-700 rounded-full px-2 py-0.5">
              {data.messageCount}
            </div>
          </div>

          {/* Message Type Stats */}
          {!data.type === 'user' && Object.keys(stats.types).length > 0 && (
            <div className="flex flex-wrap gap-1 mt-1">
              {Object.entries(stats.types).map(([type, count]) => (
                <span
                  key={type}
                  className="text-xs bg-white bg-opacity-50 rounded px-1.5 py-0.5 
                    text-gray-600 border border-gray-200"
                >
                  {type}: {count}
                </span>
              ))}
            </div>
          )}

          {/* Tool Calls Indicator */}
          {stats.toolCalls > 0 && (
            <div className="text-xs text-gray-600 flex items-center gap-1 mt-1">
              <i className="fas fa-tools"></i>
              <span>
                {stats.toolCalls} tool call{stats.toolCalls !== 1 ? 's' : ''}
              </span>
            </div>
          )}

          {/* Instance ID */}
          {data.agent_id && (
            <div className="text-xs text-gray-500 mt-1">
              ID: {data.agent_id.slice(-6)}
            </div>
          )}
        </div>
      </div>

      <Handle
        type="target"
        position={Position.Left}
        id="left"
        style={{ background: '#555' }}
      />
    </>
  )
}

// Update nodeTypes
const nodeTypes = {
  flowNode: FlowNode,
}

const ConversationFlow = ({ messages }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [selectedNode, setSelectedNode] = useState(null)

  // Fixed node positioning
  const getNodePosition = (index, totalNodes) => {
    const maxNodesPerRow = 4
    const horizontalSpacing = 270
    const verticalSpacing = 180

    const row = Math.floor(index / maxNodesPerRow)
    const col = index % maxNodesPerRow

    // Calculate horizontal center offset using a fixed container width
    const containerWidth = 1200 // Approximate width of the ReactFlow container
    const totalWidth = Math.min(maxNodesPerRow, totalNodes) * horizontalSpacing
    const xOffset = (containerWidth - totalWidth) / 2

    return {
      x: col * horizontalSpacing + xOffset,
      y: row * verticalSpacing + 50,
    }
  }

  useEffect(() => {
    if (!messages?.length) return

    const flowNodes = []
    const flowEdges = []
    const nodeMap = new Map() // Track nodes by unique ID
    let lastNodeId = null

    messages.forEach((msg, index) => {
      let nodeId
      let nodeData

      if (msg.role === 'user') {
        // For user messages, create a unique ID based on the message index
        nodeId = `user_${index}`
        nodeData = {
          id: nodeId,
          type: 'flowNode',
          position: getNodePosition(flowNodes.length, messages.length),
          data: {
            type: 'user',
            label: 'User',
            messageCount: 1,
            messages: [msg],
          },
        }
        flowNodes.push(nodeData)
      } else if (msg.role === 'assistant' || msg.role === 'system') {
        // For agent messages, use agent_id if available, otherwise create a unique ID
        nodeId = msg.agent_id
          ? `${msg.agent_name}_${msg.agent_id}`
          : `${msg.agent_name}_${index}`

        // Check if we already have a node for this specific agent instance
        const existingNode = nodeMap.get(nodeId)

        if (!existingNode) {
          nodeData = {
            id: nodeId,
            type: 'flowNode',
            position: getNodePosition(flowNodes.length, messages.length),
            data: {
              type: 'agent',
              label: msg.agent_name || 'AI Assistant',
              messageCount: 1,
              messages: [msg],
              agent_id: msg.agent_id,
            },
          }
          nodeMap.set(nodeId, nodeData)
          flowNodes.push(nodeData)
        } else {
          // Update existing node for this agent instance
          existingNode.data.messageCount++
          existingNode.data.messages.push(msg)
        }
      }

      // Create edges based on message sequence
      if (lastNodeId && nodeId && lastNodeId !== nodeId) {
        flowEdges.push({
          id: `edge-${lastNodeId}-${nodeId}-${index}`,
          source: lastNodeId,
          target: nodeId,
          sourceHandle: 'right',
          targetHandle: 'left',
          type: 'smoothstep',
          animated: true,
          style: { stroke: '#888', strokeWidth: 2 },
          markerEnd: {
            type: 'arrowclosed',
            color: '#888',
          },
        })
      }

      lastNodeId = nodeId
    })

    setNodes(flowNodes)
    setEdges(flowEdges)
  }, [messages])

  const onNodeClick = (event, clickedNode) => {
    event.preventDefault()
    setSelectedNode((prevNode) =>
      prevNode?.id === clickedNode.id ? null : clickedNode
    )
  }

  // Add ESC key handler
  const handleEscKey = useCallback((event) => {
    if (event.key === 'Escape') {
      setSelectedNode(null)
    }
  }, [])

  // Add event listener for ESC key
  useEffect(() => {
    if (selectedNode) {
      document.addEventListener('keydown', handleEscKey)
      return () => {
        document.removeEventListener('keydown', handleEscKey)
      }
    }
  }, [selectedNode, handleEscKey])

  return (
    <div className="h-[calc(100vh-350px)] relative bg-gray-50 rounded-lg">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={onNodeClick}
        nodeTypes={nodeTypes}
        fitView={false} // Disable fitView
        defaultViewport={{
          // Set default viewport
          x: 0,
          y: 0,
          zoom: 0.6,
        }}
        minZoom={0.4}
        maxZoom={1.5}
        defaultEdgeOptions={{
          type: 'smoothstep',
          animated: true,
          style: { stroke: '#888', strokeWidth: 2 },
          markerEnd: {
            type: 'arrowclosed',
            color: '#888',
          },
        }}
        connectionMode="loose"
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={true}
        snapToGrid={true}
        snapGrid={[15, 15]}
        proOptions={{ hideAttribution: true }}
      >
        <Controls
          showZoom={false}
          showFitView={false} // Hide fit view button
          showInteractive={false}
          className="bg-white"
        />
        <Background gap={16} size={1} color="#f1f1f1" variant="dots" />

        {selectedNode && (
          <Panel
            position="bottom"
            className="bg-white rounded-t-lg shadow-lg border border-gray-200 p-4 max-h-[700px] overflow-y-auto
              absolute bottom-0 w-[900px] mx-auto"
            style={{
              zIndex: 1000,
              maxWidth: '95%',
            }}
          >
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                <i
                  className={
                    selectedNode.data.type === 'user'
                      ? 'fas fa-user'
                      : 'fas fa-robot'
                  }
                ></i>
                <h4 className="font-medium text-lg text-gray-900">
                  {selectedNode.data.label} Messages
                </h4>
              </div>
              <button
                onClick={() => setSelectedNode(null)}
                className="text-gray-400 hover:text-gray-600"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="space-y-2">
              {selectedNode.data.messages.map((message, idx) => (
                <MessageItem key={idx} message={message} />
              ))}
            </div>
          </Panel>
        )}
      </ReactFlow>
    </div>
  )
}

const ViewToggle = ({ view, onViewChange }) => {
  return (
    <div className="flex rounded-lg shadow-sm bg-gray-100 p-0.5" role="group">
      <button
        className={`px-3 py-1.5 text-sm font-medium rounded-md transition-colors
          ${
            view === 'messages'
              ? 'bg-white text-gray-900 shadow-sm'
              : 'text-gray-600 hover:text-gray-900'
          }`}
        onClick={() => onViewChange('messages')}
      >
        <i className="fas fa-comments mr-2"></i>
        Messages
      </button>
      <button
        className={`px-3 py-1.5 text-sm font-medium rounded-md transition-colors
          ${
            view === 'flow'
              ? 'bg-white text-gray-900 shadow-sm'
              : 'text-gray-600 hover:text-gray-900'
          }`}
        onClick={() => onViewChange('flow')}
      >
        <i className="fas fa-project-diagram mr-2"></i>
        Agent Flow
      </button>
    </div>
  )
}

const DashboardConversations = () => {
  const [conversations, setConversations] = useState([])
  const [selectedConversation, setSelectedConversation] = useState(null)
  const [selectedMessages, setSelectedMessages] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [activeView, setActiveView] = useState('messages')

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const auth = getAuth()
        const token = await auth.currentUser?.getIdToken()

        const response = await fetch(`${API_ENDPOINTS.CONVERSATIONS}/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (response.ok) {
          const data = await response.json()
          setConversations(data)

          // If there are conversations and none is selected, select the first one
          if (data.length > 0 && !selectedConversation) {
            const firstConversation = data[0]
            setSelectedConversation(firstConversation)
            // Fetch messages for the first conversation
            await fetchMessages(firstConversation.id)
          }
        } else {
          toast.error('Failed to fetch conversations')
        }
      } catch (error) {
        console.error('Error fetching conversations:', error)
        toast.error('Error loading conversations')
      } finally {
        setLoading(false)
      }
    }

    fetchInitialData()
  }, []) // Keep empty dependency array since we only want this on mount

  const fetchMessages = async (conversationId) => {
    setLoadingMessages(true)
    try {
      const auth = getAuth()
      const token = await auth.currentUser?.getIdToken()

      const response = await fetch(
        `${API_ENDPOINTS.CONVERSATIONS}/${conversationId}/messages`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.ok) {
        const messages = await response.json()
        setSelectedMessages(messages)
      } else {
        toast.error('Failed to fetch messages')
      }
    } catch (error) {
      console.error('Error fetching messages:', error)
      toast.error('Error loading messages')
    } finally {
      setLoadingMessages(false)
    }
  }

  const handleConversationSelect = async (conversation) => {
    setSelectedConversation(conversation)
    setSelectedMessages([]) // Clear previous messages
    await fetchMessages(conversation.id)
  }

  const renderConversationTitle = (conversation) => {
    if (conversation.title) {
      return conversation.title
    }
    // Fallback to ID if no title
    return `Conversation ${conversation.id.slice(0, 25)}...`
  }

  const renderActiveView = () => {
    if (!selectedConversation) {
      return (
        <div className="flex items-center justify-center h-64 text-gray-500">
          Select a conversation to view details
        </div>
      )
    }

    if (loadingMessages) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="text-gray-500">Loading messages...</div>
        </div>
      )
    }

    if (activeView === 'flow') {
      return <ConversationFlow messages={selectedMessages} />
    }

    return (
      <div className="space-y-4">
        {selectedMessages.map((message, index) => (
          <MessageItem key={message.id || index} message={message} />
        ))}
      </div>
    )
  }

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="text-gray-600">Loading conversations...</div>
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1 overflow-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900">
              Conversation History
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              View and analyze your chat history with the AI assistant
            </p>
          </div>

          {/* Combined table layout */}
          <div className="bg-white rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="flex h-[calc(100vh-220px)]">
              {/* Conversations List - Left Panel */}
              <div className="w-[350px] border-r border-gray-200 flex flex-col">
                <div className="p-4 border-b border-gray-200 bg-gray-50 h-[60px] flex items-center">
                  <h3 className="text-lg font-medium text-gray-900">
                    Conversations
                  </h3>
                </div>
                <div className="flex-1 overflow-y-auto">
                  <div className="divide-y divide-gray-200">
                    {conversations
                      .sort(
                        (a, b) =>
                          new Date(b.updated_at) - new Date(a.updated_at)
                      )
                      .map((conversation) => (
                        <div
                          key={conversation.id}
                          onClick={() => handleConversationSelect(conversation)}
                          className={`p-4 cursor-pointer transition-colors duration-150 mx-2 my-1 rounded-lg
                            ${
                              selectedConversation?.id === conversation.id
                                ? 'bg-gray-50 border-l-4 border-gray-200'
                                : 'hover:bg-gray-50/60'
                            }`}
                        >
                          <div className="flex items-center justify-between">
                            <div>
                              <p className="text-sm font-medium text-gray-900">
                                {renderConversationTitle(conversation)}
                              </p>
                              <p className="text-xs text-gray-500 mt-1">
                                {new Date(
                                  conversation.updated_at
                                ).toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Conversation Details - Right Panel */}
              <div className="flex-1 flex flex-col">
                <div className="p-4 border-b border-gray-200 bg-gray-50 h-[60px] flex items-center justify-between">
                  <h3 className="text-lg font-medium text-gray-900">
                    {selectedConversation
                      ? `Conversation Details`
                      : 'Select a Conversation'}
                  </h3>
                  {selectedConversation && !loadingMessages && (
                    <ViewToggle
                      view={activeView}
                      onViewChange={setActiveView}
                    />
                  )}
                </div>

                <div className="flex-1 overflow-y-auto p-4 bg-gray-50">
                  {!selectedConversation ? (
                    <div className="flex items-center justify-center h-full text-gray-500">
                      Select a conversation to view details
                    </div>
                  ) : loadingMessages ? (
                    <div className="flex items-center justify-center h-full">
                      <div className="text-gray-500">Loading messages...</div>
                    </div>
                  ) : activeView === 'flow' ? (
                    <div className="h-full">
                      <ConversationFlow messages={selectedMessages} />
                    </div>
                  ) : (
                    <div className="space-y-4">
                      {selectedMessages.map((message, index) => (
                        <MessageItem
                          key={message.id || index}
                          message={message}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardConversations
