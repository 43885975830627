import React from 'react'
import projectsData from '../../data/projects.json'

function MiniProjects() {
  return (
    <section id="mini-projects" className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Mini Projects</h2>
          <h4 className="text-xl text-gray-500">
            Tiny time buffers of my life
          </h4>
        </div>
        <div className="postInner">
          <div className="flex flex-wrap -mx-4">
            {[...projectsData].reverse().map((project, index) => (
              <article
                key={index}
                className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8"
              >
                <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:transform hover:scale-105">
                  <a
                    className="block"
                    href={project.github_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="h-48 bg-cover bg-center"
                      style={{ backgroundImage: `url(${project.image})` }}
                    ></div>
                  </a>
                  <div className="p-6 flex flex-col flex-grow">
                    <a
                      className="flex-grow"
                      href={project.github_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <header className="mb-4">
                        <div className="flex flex-wrap mb-2">
                          {project.tags.map((tag, tagIndex) => (
                            <span
                              key={tagIndex}
                              className="inline-block bg-gray-200 text-gray-700 text-xs px-2 py-1 rounded mr-2 mb-2"
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                        <h2 className="text-xl font-semibold mb-2">
                          {project.title}
                        </h2>
                      </header>
                      <section className="text-gray-600 text-sm mb-4">
                        <p>{project.description}</p>
                      </section>
                    </a>
                    <footer className="mt-auto flex justify-end items-center">
                      <img
                        className="w-6 h-6"
                        src={`assets/images/projects/platform/${project.platform}.png`}
                        alt={project.platform}
                      />
                    </footer>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default MiniProjects
