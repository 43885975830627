import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUIActionContext } from '../contexts/UIActionContext'
import { isValidPath } from '../utils/routes'

const useUIActions = (sendMessage) => {
  const { setActionStatus } = useUIActionContext()
  const navigate = useNavigate()

  const memoizedSetActionStatus = useMemo(
    () => setActionStatus,
    [setActionStatus]
  )

  const handleUIAction = useCallback(
    async (action) => {
      try {
        memoizedSetActionStatus({
          isExecuting: true,
          lastAction: action,
          error: null,
        })

        switch (action.action) {
          case 'navigate':
            if (!action.target) {
              throw new Error('Navigation target not specified')
            }
            if (!isValidPath(action.target)) {
              throw new Error(`Invalid navigation path: ${action.target}`)
            }
            await navigate(action.target)
            break

          case 'input':
            const element =
              document.getElementById(action.target) ||
              document.querySelector(action.target)

            if (!element) {
              throw new Error(`Input element not found: ${action.target}`)
            }

            // Focus the element
            element.focus()

            // Clear first if specified
            if (action.params?.clear_first) {
              element.value = ''
            }

            // Set the value
            element.value = action.params.value

            // Create and dispatch an input event
            const inputEvent = new Event('input', {
              bubbles: true,
              composed: true,
            })
            Object.defineProperty(inputEvent, 'target', {
              value: element,
              enumerable: true,
            })
            element.dispatchEvent(inputEvent)

            // Create and dispatch a change event
            const changeEvent = new Event('change', {
              bubbles: true,
              composed: true,
            })
            Object.defineProperty(changeEvent, 'target', {
              value: element,
              enumerable: true,
            })
            element.dispatchEvent(changeEvent)

            // For React controlled inputs, we need to trigger the React onChange handler
            const reactHandler = Object.keys(element).find((key) =>
              key.startsWith('__reactProps$')
            )
            if (reactHandler) {
              const props = element[reactHandler]
              if (props.onChange) {
                props.onChange({ target: element })
              }
            }
            break

          case 'click':
            const clickElement =
              document.getElementById(action.target) ||
              document.querySelector(action.target)

            if (!clickElement) {
              throw new Error(`Click element not found: ${action.target}`)
            }
            clickElement.click()
            break

          default:
            console.warn('Unknown action type:', action.action)
            break
        }

        memoizedSetActionStatus({
          isExecuting: false,
          lastAction: action,
          error: null,
        })
      } catch (error) {
        console.error('Error executing UI action:', error)
        memoizedSetActionStatus({
          isExecuting: false,
          lastAction: action,
          error: error.message,
        })
      }
    },
    [memoizedSetActionStatus, navigate]
  )

  return {
    handleUIAction,
  }
}

export default useUIActions
