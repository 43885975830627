import yaml from 'js-yaml'

export const loadBlogPosts = async () => {
  const blogPosts = []

  try {
    // Get all yaml files
    const yamlFiles = require.context('../data/blog-posts', false, /\.yaml$/)

    // Process each yaml file
    for (const key of yamlFiles.keys()) {
      try {
        // Fetch the raw content using fetch
        const response = await fetch(yamlFiles(key))
        const yamlText = await response.text()

        // Parse YAML content
        const parsedContent = yaml.load(yamlText)

        if (parsedContent) {
          blogPosts.push({
            ...parsedContent,
            timestamp: new Date(parsedContent.date),
          })
        }
      } catch (parseError) {
        console.error('Error processing YAML file:', key, parseError)
      }
    }
  } catch (error) {
    console.error('Error loading blog posts:', error)
  }

  // Sort posts by date, newest first
  return blogPosts.sort((a, b) => b.timestamp - a.timestamp)
}
