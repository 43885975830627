import React from 'react'
import config from '../../config'

function About() {
  return (
    <header
      className="relative flex items-center justify-center w-full h-[50vh] text-center bg-cover bg-center"
      style={{ backgroundImage: `url(${config.cover})` }}
    >
      <div className="absolute inset-0 bg-black opacity-40"></div>

      <div className="relative z-10 w-full">
        <div className="main-header-content inner max-w-4xl mx-auto px-4">
          <h1 className="page-title text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4 whitespace-nowrap overflow-hidden overflow-ellipsis">
            {config.name}
          </h1>
          <h2 className="page-description text-xl sm:text-2xl font-normal text-white opacity-80 whitespace-nowrap overflow-hidden overflow-ellipsis">
            {config.description}
          </h2>
        </div>
      </div>
    </header>
  )
}

export default About
