import { useRef, useCallback } from 'react'

const STORAGE_KEY = 'research_paper_data'

const getStoredPaperData = () => {
  try {
    const stored = localStorage.getItem(STORAGE_KEY)
    return stored ? JSON.parse(stored) : {
      pdf_url: '',
      content: '',
      summary: '',
      key_findings: [],
      qa: [],
      methodology: [],
      discussion: {
        strengths: [],
        limitations: [],
        future_work: [],
      },
    }
  } catch (e) {
    console.error('Error reading from localStorage:', e)
    return null
  }
}

const useResearchPaperManager = (sendMessage) => {
  const paperDataRef = useRef(getStoredPaperData())

  const updatePaperData = useCallback((data) => {
    if (!data) return
    paperDataRef.current = { ...data }
    // Store in localStorage
    localStorage.setItem(STORAGE_KEY, JSON.stringify(paperDataRef.current))
  }, [])

  const sendResearchPaperContent = useCallback(() => {
    if (sendMessage && paperDataRef.current) {
      sendMessage({
        type: 'research_paper_content',
        content: paperDataRef.current,
      })
    }
  }, [sendMessage])

  return {
    updatePaperData,
    sendResearchPaperContent,
    paperDataRef,
  }
}

export default useResearchPaperManager
