import React from 'react'
import VisualizationSection from './Canvas/VisualizationSection'
import { sampleData } from './constants/sampleData'

const DashboardCanvas = ({ visualizationData }) => {
  return (
    <div className="h-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-bold text-gray-900">Dynamic Canvas</h1>
            <p className="mt-2 text-sm text-gray-600">
              Real-time interactive visualization dashboard powered by AI
            </p>
          </div>
        </div>

        <div className="space-y-8">
          <VisualizationSection
            content={visualizationData?.content || sampleData.content}
          />
        </div>
      </div>
    </div>
  )
}

export default DashboardCanvas
