import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import { CHART_STYLES } from '../constants/chartStyles'

// Add these lines to fix Leaflet marker icon issue
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const MapChart = ({ data, config }) => {
  if (!data[0]?.lat) return null

  return (
    <div className="relative w-full h-[400px] overflow-hidden rounded-xl">
      <MapContainer
        center={config.center || [20, 0]}
        zoom={config.zoom || 2}
        minZoom={2}
        maxBounds={[
          [-85, -Infinity],
          [85, Infinity],
        ]}
        maxBoundsViscosity={1.0}
        style={{
          width: '100%',
          height: '100%',
          background: '#f8fafc',
        }}
        className="rounded-xl"
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution=""
          url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=9174eaa7-3909-4f40-8086-cbdd9ce0b068"
          noWrap={false}
          bounds={[
            [-85, -180],
            [85, 180],
          ]}
        />
        {data.map((location, index) => (
          <Marker
            key={index}
            position={[location.lat, location.lng]}
            icon={L.divIcon({
              className: 'custom-marker-icon',
              html: `
                <div class="w-6 h-6 bg-indigo-500 rounded-full border-2 border-white shadow-lg flex items-center justify-center transform -translate-x-1/2 -translate-y-1/2">
                  <div class="w-2 h-2 bg-white rounded-full"></div>
                </div>
              `,
              iconSize: [24, 24],
              iconAnchor: [12, 12],
            })}
          >
            <Popup className="rounded-lg">
              <div className="p-2 min-w-[200px]">
                <h3 className="text-lg font-bold text-indigo-600 mb-1">
                  {location.title}
                </h3>
                {location.description && (
                  <p className="text-gray-600 text-sm">
                    {location.description}
                  </p>
                )}
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  )
}

export default MapChart 