import React, { useState, useEffect, useCallback } from 'react'
import { API_ENDPOINTS } from '../../config'
import { getAuth } from 'firebase/auth'
import toast from 'react-hot-toast' // Add this import

const DashboardSettings = () => {
  const [apiKey, setApiKey] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasApiKey, setHasApiKey] = useState(false)
  const [displayedApiKey, setDisplayedApiKey] = useState('')

  const fetchApiKey = useCallback(async () => {
    try {
      const auth = getAuth()
      const token = await auth.currentUser?.getIdToken()

      const response = await fetch(`${API_ENDPOINTS.API_KEYS}/openai`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.ok) {
        const data = await response.json()
        const key = data.api_key || ''
        setApiKey(key)
        setDisplayedApiKey(maskApiKey(key))
        setHasApiKey(true)
      }
    } catch (error) {
      console.error('Error fetching API key:', error)
      toast.error('Failed to load API key') // Replace setError with toast.error
    }
  }, [])

  useEffect(() => {
    fetchApiKey()
  }, [fetchApiKey])

  const maskApiKey = (key) => {
    if (!key) return ''
    // Show first 3 and last 4 characters, mask the rest
    return `${key.slice(0, 3)}${'•'.repeat(Math.max(0, key.length - 7))}${key.slice(-4)}`
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const auth = getAuth()
      const token = await auth.currentUser?.getIdToken()

      const response = await fetch(API_ENDPOINTS.API_KEYS, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          openai_key: apiKey,
        }),
      })

      if (!response.ok) {
        throw new Error('Failed to save API key')
      }

      toast.success('API key saved successfully') // Replace setSuccess with toast.success
      setDisplayedApiKey(maskApiKey(apiKey))
      setHasApiKey(true)
    } catch (error) {
      toast.error(error.message) // Replace setError with toast.error
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setApiKey(value)
    setDisplayedApiKey(value) // Update displayed value in real-time while typing
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900">API Settings</h2>
        <p className="mt-1 text-sm text-gray-500">
          Manage your API keys and integrations
        </p>
      </div>

      <div className="bg-white rounded-lg shadow divide-y divide-gray-200">
        {/* OpenAI API Key Section */}
        <div className="p-6">
          <div className="flex items-start justify-between">
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                OpenAI API Key
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Your OpenAI API key is required for the AI chat functionality.
                {hasApiKey && (
                  <span className="ml-2 text-green-600">
                    (✓ API key is set)
                  </span>
                )}
              </p>
            </div>
            {hasApiKey && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                Active
              </span>
            )}
          </div>

          <form onSubmit={handleSubmit} className="mt-6 space-y-4">
            <div>
              <label
                htmlFor="apiKey"
                className="block text-sm font-medium text-gray-700"
              >
                API Key
              </label>
              <div className="mt-1 flex gap-3">
                <input
                  type="text"
                  id="apiKey"
                  value={displayedApiKey}
                  onChange={handleInputChange}
                  className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md font-mono"
                  placeholder="sk-..."
                  required
                />
                <button
                  type="submit"
                  disabled={loading}
                  className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white 
                    ${loading ? 'bg-gray-700 cursor-not-allowed' : 'bg-gray-600 hover:bg-gray-700'}
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 whitespace-nowrap`}
                >
                  {loading ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Saving...
                    </>
                  ) : (
                    'Save API Key'
                  )}
                </button>
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Your API key will be encrypted before storage
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default DashboardSettings
