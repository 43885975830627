import React from 'react'
import skillsData from '../../data/skills.json'

function Skills() {
  return (
    <section id="skills" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Skillsets</h2>
          <h4 className="text-xl text-gray-500">
            Experiences I've come across
          </h4>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-2">
          {Object.entries(skillsData).map(([key, skill]) => (
            <div key={key} className="flex flex-col items-center group">
              <div className="circle bg-white p-3 rounded-full transition-all duration-300 group-hover:shadow-lg group-hover:scale-110">
                <img
                  src={skill.image}
                  alt={skill.alt}
                  className="w-16 h-16 object-contain"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Skills
