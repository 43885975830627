import React from 'react'
import ReactMarkdown from 'react-markdown'

const MarkdownRenderer = ({ content }) => {
  return (
    <div className="text-sm text-gray-700 prose prose-sm max-w-none">
      <ReactMarkdown
        components={{
          // Style code blocks
          code: ({ node, inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '')
            const language = match ? match[1] : ''
            return !inline ? (
              <div className="relative">
                {language && (
                  <div className="absolute right-2 top-2 text-xs text-gray-400">
                    {language}
                  </div>
                )}
                <pre className="bg-gray-800 text-white rounded-md p-4 overflow-x-auto">
                  <code className={className} {...props}>
                    {children}
                  </code>
                </pre>
              </div>
            ) : (
              <code
                className="bg-gray-100 text-gray-800 rounded px-1 py-0.5"
                {...props}
              >
                {children}
              </code>
            )
          },
          // Style links
          a: ({ node, children, ...props }) => (
            <a
              className="text-blue-600 hover:text-blue-800"
              target="_blank"
              rel="noopener noreferrer"
              {...props}
            >
              {children}
            </a>
          ),
          // Style paragraphs
          p: ({ node, children }) => (
            <p className="mb-4 last:mb-0">{children}</p>
          ),
          // Style lists
          ul: ({ node, children }) => (
            <ul className="list-disc pl-4 mb-4">{children}</ul>
          ),
          ol: ({ node, children }) => (
            <ol className="list-decimal pl-4 mb-4">{children}</ol>
          ),
          // Style headings
          h1: ({ node, children }) => (
            <h1 className="text-xl font-bold mb-4">{children}</h1>
          ),
          h2: ({ node, children }) => (
            <h2 className="text-lg font-bold mb-3">{children}</h2>
          ),
          h3: ({ node, children }) => (
            <h3 className="text-md font-bold mb-2">{children}</h3>
          ),
          blockquote: ({ node, children }) => (
            <blockquote className="border-l-4 border-gray-200 pl-3 my-2 italic">
              {children}
            </blockquote>
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  )
}

export default MarkdownRenderer
