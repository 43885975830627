import React, { useState, useEffect, useCallback } from 'react'
import { Outlet } from 'react-router-dom'
import Chatbot from './Chatbot'

const Dashboard = () => {
  const [isChatbotOpen, setIsChatbotOpen] = useState(true)
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false)
  const [isChatbotFocused, setIsChatbotFocused] = useState(true)
  const [hasUnread, setHasUnread] = useState(false)
  const [messages, setMessages] = useState([])
  const [isWaitingResponse, setIsWaitingResponse] = useState(false)
  const [conversationId, setConversationId] = useState(null)

  // Define handleNewChat first
  const handleNewChat = useCallback(() => {
    setMessages([])
    const newConversationId = crypto.randomUUID()
    setConversationId(newConversationId)

    // Open in the currently active mode
    if (isChatWindowOpen) {
      setIsChatWindowOpen(true)
      setIsChatbotOpen(false)
    } else {
      setIsChatbotOpen(true)
      setIsChatWindowOpen(false)
    }
    setHasUnread(false)
  }, [isChatWindowOpen])

  // Then use it in the keyboard handler
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        // Only close if in window mode
        if (isChatWindowOpen) {
          setIsChatWindowOpen(false)
        }
      } else if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
        // Handle window mode first
        if (isChatWindowOpen) {
          setIsChatWindowOpen(false)
        }
        // Handle sidebar focus/close behavior
        if (isChatbotOpen) {
          if (isChatbotFocused) {
            setIsChatbotOpen(false)
          } else {
            setIsChatbotFocused(true)
          }
        } else {
          setIsChatbotOpen(true)
          setIsChatbotFocused(true)
        }
        setHasUnread(false)
      } else if ((event.metaKey || event.ctrlKey) && event.key === 'i') {
        event.preventDefault()
        if (isChatbotOpen) {
          setIsChatbotOpen(false)
          setIsChatbotFocused(false)
        }
        setTimeout(() => {
          setIsChatWindowOpen((prev) => !prev)
        }, 150)
        setHasUnread(false)
      } else if ((event.metaKey || event.ctrlKey) && event.key === 'j') {
        event.preventDefault()
        handleNewChat()
      }
    }

    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [isChatWindowOpen, isChatbotOpen, isChatbotFocused, handleNewChat])

  // Handle click outside to blur chatbot
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside chatbot
      if (isChatbotOpen && !event.target.closest('.chatbot-container')) {
        setIsChatbotFocused(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isChatbotOpen])

  // Modify handleNewMessage to better handle history messages
  const handleNewMessage = useCallback(
    (message, isStreaming = false, isHistory = false) => {
      setMessages((prev) => {
        if (isHistory) {
          return message
        }

        if (isStreaming && prev.length > 0) {
          const lastMessage = prev[prev.length - 1]
          if (lastMessage.type === 'bot') {
            return [
              ...prev.slice(0, -1),
              {
                ...lastMessage,
                content: lastMessage.content + message.content,
              },
            ]
          }
        }

        return [...prev, message]
      })

      if (message.type === 'user') {
        setIsWaitingResponse(true)
      } else if (message.type === 'bot') {
        if (!isStreaming) {
          setIsWaitingResponse(false)
          if (!isChatbotOpen) {
            setHasUnread(true)
          }
        }
      } else if (message.type === 'complete') {
        setIsWaitingResponse(false)
      }
    },
    [isChatbotOpen]
  )

  useEffect(() => {
    const savedConversationId = localStorage.getItem('chatConversationId')
    if (savedConversationId) {
      setConversationId(savedConversationId)
    } else {
      const newConversationId = crypto.randomUUID()
      setConversationId(newConversationId)
    }
  }, [])

  useEffect(() => {
    if (conversationId) {
      localStorage.setItem('chatConversationId', conversationId)
    }
  }, [conversationId])

  const activeChatMode = isChatWindowOpen
    ? 'window'
    : isChatbotOpen
      ? 'sidebar'
      : null

  return (
    <div className="flex h-screen">
      <div
        className={`
          flex-1 
          ${isChatbotOpen && !isChatWindowOpen ? 'mr-[500px]' : ''} 
          transition-all duration-150 
          bg-gray-100
          overflow-hidden
        `}
      >
        <div className="h-full overflow-y-auto scrollbar-hide">
          <Outlet />
        </div>
      </div>

      {activeChatMode && (
        <div
          className={`
            ${
              activeChatMode === 'window'
                ? 'fixed inset-0 z-50'
                : 'fixed top-0 right-4 w-[480px] h-screen'
            }
            chatbot-container
          `}
          onClick={() => setIsChatbotFocused(true)}
        >
          <Chatbot
            isOpen={true}
            onClose={() => {
              setIsChatbotOpen(false)
              setIsChatWindowOpen(false)
              setIsChatbotFocused(false)
            }}
            messages={messages}
            onNewMessage={handleNewMessage}
            onNewChat={handleNewChat}
            isWindowMode={activeChatMode === 'window'}
            conversationId={conversationId}
            isWaitingResponse={isWaitingResponse}
            isFocused={isChatbotFocused}
          />
        </div>
      )}

      {!activeChatMode && (
        <button
          onClick={() => setIsChatbotOpen(true)}
          className="fixed bottom-6 right-6 w-16 h-16 bg-gray-700/90 rounded-full shadow-lg 
            flex items-center justify-center text-white hover:bg-gray-600/90 
            transition-colors duration-200 focus:outline-none focus:ring-2 
            focus:ring-offset-2 focus:ring-gray-500 p-2 z-50"
          aria-label="Open chat"
        >
          <img
            src="/assets/images/logo/grainer-logo.png"
            alt="Chat"
            className="w-full h-full object-contain"
          />
          {(hasUnread || isWaitingResponse) && (
            <span
              className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full 
              flex items-center justify-center"
            >
              <span
                className="animate-pulse absolute inline-flex h-full w-full 
                rounded-full bg-red-400 opacity-75"
              ></span>
            </span>
          )}
        </button>
      )}
    </div>
  )
}

export default Dashboard
