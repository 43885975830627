import React from 'react'
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { CHART_COLORS, CHART_STYLES } from '../constants/chartStyles'

const ClusterChart = ({ data, config }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
        <CartesianGrid strokeDasharray="3 3" stroke={CHART_STYLES.gridColor} />
        <XAxis
          type="number"
          dataKey={config.xAxis}
          name="Value"
          tick={{ fill: CHART_COLORS.neutral }}
          axisLine={{ stroke: CHART_STYLES.gridColor }}
        />
        <YAxis
          type="number"
          dataKey={config.yAxis}
          name="Score"
          tick={{ fill: CHART_COLORS.neutral }}
          axisLine={{ stroke: CHART_STYLES.gridColor }}
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          contentStyle={{
            backgroundColor: CHART_STYLES.tooltipBackground,
            borderRadius: '0.5rem',
            border: 'none',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
          }}
          formatter={(value, name, props) => [value, props.payload[config.labelKey]]}
        />
        <Legend />
        {Object.keys(config.clusters).map((clusterName) => (
          <Scatter
            key={clusterName}
            name={clusterName}
            data={data.filter((item) => item[config.clusterKey] === clusterName)}
            fill={config.clusters[clusterName]}
          >
          </Scatter>
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export default ClusterChart 