import { API_ENDPOINTS } from '../config'
import { getAuth } from 'firebase/auth'

const getAuthHeaders = async () => {
  const auth = getAuth()
  const user = auth.currentUser
  if (!user) throw new Error('No authenticated user found')

  const token = await user.getIdToken()

  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  }
}

export const promptService = {
  async getPrompts() {
    const headers = await getAuthHeaders()
    const response = await fetch(`${API_ENDPOINTS.PROMPTS}`, {
      headers,
    })
    if (!response.ok) throw new Error('Failed to fetch prompts')
    return response.json()
  },

  async createPrompt(prompt) {
    const headers = await getAuthHeaders()
    const response = await fetch(`${API_ENDPOINTS.PROMPTS}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(prompt),
    })
    if (!response.ok) throw new Error('Failed to create prompt')
    return response.json()
  },

  async updatePrompt(id, prompt) {
    const headers = await getAuthHeaders()
    const response = await fetch(`${API_ENDPOINTS.PROMPTS}/${id}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(prompt),
    })
    if (!response.ok) throw new Error('Failed to update prompt')
    return response.json()
  },

  async deletePrompt(id) {
    const headers = await getAuthHeaders()
    const response = await fetch(`${API_ENDPOINTS.PROMPTS}/${id}`, {
      method: 'DELETE',
      headers,
    })
    if (!response.ok) throw new Error('Failed to delete prompt')
    return response.json()
  },

  async getPromptVersions(name) {
    const headers = await getAuthHeaders()
    const response = await fetch(`${API_ENDPOINTS.PROMPTS}/${name}/versions`, {
      headers,
    })
    if (!response.ok) throw new Error('Failed to fetch prompt versions')
    return response.json()
  },

  async setPromptProduction(id, isProduction) {
    const headers = await getAuthHeaders()
    const response = await fetch(`${API_ENDPOINTS.PROMPTS}/${id}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({ is_production: isProduction }),
    })
    if (!response.ok)
      throw new Error('Failed to update prompt production status')
    return response.json()
  },

  async generatePrompt(content, onData) {
    const headers = await getAuthHeaders()
    const response = await fetch(`${API_ENDPOINTS.PROMPTS}/prompt-generation`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ content }),
    })

    if (!response.ok) throw new Error('Failed to generate prompt')

    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')

    while (true) {
      const { done, value } = await reader.read()
      if (done) break
      const chunk = decoder.decode(value, { stream: true })
      onData(chunk)
    }
  },
}
