import React from 'react'
import config from '../../config'

function Author() {
  return (
    <section id="about" className="pt-16 pb-16 bg-gray-100 relative">
      {/* Profile Image - Positioned to overlap with previous section */}
      <div className="absolute left-1/2 transform -translate-x-1/2 -top-12">
        <div className="relative">
          <img
            src={config.profile_picture}
            alt={config.name}
            className="w-24 h-24 rounded-full border-4 border-gray-200 object-cover bg-white"
          />
        </div>
      </div>

      <div className="container mx-auto px-4 max-w-4xl">
        <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
          {/* Left Column - Name, Title, Location */}
          <div>
            <h2 className="text-2xl font-bold mb-1">{config.name}</h2>
            <h3 className="text-lg text-gray-600 mb-2">{config.bio}</h3>
            <div className="flex items-center space-x-4 text-gray-500">
              <span className="flex items-center">
                <i className="fas fa-map-marker-alt mr-2"></i>
                {config.location}
              </span>
              <span className="flex items-center">
                <i className="fas fa-link mr-2"></i>
                <a
                  href={`https://${config.github_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:text-gray-700"
                >
                  {config.github_url}
                </a>
              </span>
            </div>
          </div>

          {/* Right Column - Contact Links */}
          <div className="mt-4 md:mt-0">
            <p className="text-gray-600 mb-2 text-right">
              Feel free to contact me
            </p>
            <div className="flex justify-end space-x-6">
              <a
                href={`https://${config.twitter_url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-gray-700"
              >
                <i className="fab fa-twitter fa-lg"></i>
              </a>
              <a
                href={`https://${config.linkedin_url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-gray-700"
              >
                <i className="fab fa-linkedin-in fa-lg"></i>
              </a>
              <a
                href={`https://${config.github_url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-gray-700"
              >
                <i className="fab fa-github fa-lg"></i>
              </a>
              <a
                href={`mailto:${config.email}`}
                className="text-gray-500 hover:text-gray-700"
              >
                <i className="fas fa-envelope fa-lg"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Author
