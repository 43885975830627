import React, { useState, useEffect, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { promptService } from '../../services/promptService'
import toast from 'react-hot-toast'
import { FaMagic } from 'react-icons/fa'

const TextAreaWithCount = ({
  value,
  onChange,
  rows = 4,
  maxLength = 10000,
}) => {
  const charCount = value.length
  const isNearLimit = charCount > maxLength * 0.9
  const isAtLimit = charCount >= maxLength

  return (
    <div className="relative">
      <div className="rounded-lg overflow-hidden border border-gray-200 shadow-inner">
        <textarea
          value={value}
          onChange={onChange}
          rows={rows}
          maxLength={maxLength}
          className={`mt-0 block w-full px-4 py-3
            focus:outline-none focus:ring-0
            font-mono text-sm
            resize-none leading-relaxed
            ${isAtLimit ? 'border-red-300' : 'border-transparent'}
            transition-colors duration-200`}
          style={{
            lineHeight: '1.6',
            tabSize: 2,
            backgroundColor: '#f8fafc', // Light gray background
            caretColor: '#3b82f6', // Blue cursor
            boxShadow: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
          }}
          spellCheck="false"
          wrap="soft"
        />
      </div>
      <div
        className={`absolute bottom-3 right-3 px-2 py-1 text-xs rounded-md
          ${
            isAtLimit
              ? 'bg-red-100 text-red-600'
              : isNearLimit
                ? 'bg-orange-100 text-orange-600'
                : 'bg-gray-100 text-gray-500'
          }
          transition-colors duration-200`}
      >
        {charCount}/{maxLength}
      </div>
    </div>
  )
}

const VersionSelector = ({ versions, currentVersion, onVersionSelect }) => {
  const formatDateTime = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }
    return new Date(dateString).toLocaleString('en-US', options)
  }

  return (
    <div
      className="w-64 border-l border-gray-200 pl-4 overflow-y-auto"
      style={{ maxHeight: '80vh' }}
    >
      <h4 className="text-sm font-medium text-gray-900 mb-2">Versions</h4>
      <div className="space-y-2">
        {versions.map((version) => (
          <button
            key={version.id}
            onClick={() => onVersionSelect(version)}
            className={`w-full text-left px-3 py-2 rounded-md text-sm ${
              currentVersion.id === version.id
                ? 'bg-gray-100 text-gray-900'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <div className="flex justify-between items-center">
              <span>Version {version.version}</span>
              {version.is_production && (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                  Production
                </span>
              )}
            </div>
            <div className="text-xs text-gray-500 mt-1">
              {formatDateTime(version.created_at)}
            </div>
          </button>
        ))}
      </div>
    </div>
  )
}

const DashboardPrompts = () => {
  const [prompts, setPrompts] = useState([])
  const [loading, setLoading] = useState(true)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedPrompt, setSelectedPrompt] = useState(null)
  const [newPrompt, setNewPrompt] = useState({ name: '', content: '' })
  const [promptVersions, setPromptVersions] = useState([])
  const [selectedVersion, setSelectedVersion] = useState(null)

  useEffect(() => {
    loadPrompts()
  }, [])

  // Add this useEffect to handle body scrolling
  useEffect(() => {
    if (isCreateModalOpen || isEditModalOpen || isDeleteModalOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    // Cleanup on unmount
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isCreateModalOpen, isEditModalOpen, isDeleteModalOpen])

  const loadPrompts = async () => {
    try {
      const data = await promptService.getPrompts()
      setPrompts(data)
    } catch (err) {
      toast.error('Failed to load prompts')
    } finally {
      setLoading(false)
    }
  }

  const isDuplicateName = (name, excludeName = null) => {
    return prompts.some(
      (prompt) =>
        prompt.name.toLowerCase() === name.toLowerCase() &&
        prompt.name !== excludeName
    )
  }

  const handleCreatePrompt = async (e) => {
    e.preventDefault()

    if (isDuplicateName(newPrompt.name)) {
      toast.error('A prompt with this name already exists')
      return
    }

    try {
      const created = await promptService.createPrompt(newPrompt)
      setPrompts([...prompts, created])
      setNewPrompt({ name: '', content: '' })
      setIsCreateModalOpen(false)
      toast.success('Prompt created successfully')
      await loadPrompts() // Reload prompts to get the latest version
    } catch (err) {
      toast.error('Failed to create prompt')
    }
  }

  const handleUpdatePrompt = async (e) => {
    e.preventDefault()

    try {
      const updated = await promptService.updatePrompt(
        selectedPrompt.id,
        selectedPrompt
      )
      await loadPrompts()
      if (selectedPrompt.name) {
        const versions = await promptService.getPromptVersions(
          selectedPrompt.name
        )
        setPromptVersions(versions)
        const latestVersion = versions[0]
        setSelectedVersion(latestVersion)
        setSelectedPrompt(latestVersion)
      }
      setIsEditModalOpen(false)
      toast.success('Prompt updated successfully')
    } catch (err) {
      toast.error('Failed to update prompt')
    }
  }

  const handleDeletePrompt = async () => {
    try {
      await promptService.deletePrompt(selectedPrompt.id)
      setPrompts(prompts.filter((p) => p.id !== selectedPrompt.id))
      setIsDeleteModalOpen(false)
      toast.success('Prompt deleted successfully')
    } catch (err) {
      toast.error('Failed to delete prompt')
    }
  }

  const handlePromptSelect = async (prompt) => {
    try {
      const versions = await promptService.getPromptVersions(prompt.name)
      setPromptVersions(versions)
      // Select the latest version by default
      const latestVersion = versions[0]
      setSelectedPrompt(latestVersion)
      setSelectedVersion(latestVersion)
      setIsEditModalOpen(true)
    } catch (err) {
      toast.error('Failed to load prompt versions')
    }
  }

  const handleVersionSelect = (version) => {
    setSelectedVersion(version)
    setSelectedPrompt(version)
  }

  const handleSetProduction = async () => {
    try {
      await promptService.setPromptProduction(selectedVersion.id, true)
      const updatedVersions = await promptService.getPromptVersions(
        selectedPrompt.name
      )
      setPromptVersions(updatedVersions)
      toast.success('Prompt set as production version')
    } catch (err) {
      toast.error('Failed to set production version')
    }
  }

  const handlePromptGeneration = async () => {
    const currentPrompt = isEditModalOpen ? selectedPrompt : newPrompt
    if (!currentPrompt.content) return

    try {
      // Clear the text area before starting the generation
      if (isEditModalOpen) {
        setSelectedPrompt((prev) => ({ ...prev, content: '' }))
      } else {
        setNewPrompt((prev) => ({ ...prev, content: '' }))
      }

      await promptService.generatePrompt(currentPrompt.content, (chunk) => {
        if (isEditModalOpen) {
          setSelectedPrompt((prev) => {
            const newContent = prev.content + chunk
            return { ...prev, content: newContent }
          })
        } else {
          setNewPrompt((prev) => {
            const newContent = prev.content + chunk
            return { ...prev, content: newContent }
          })
        }
      })

      toast.success('Prompt generation completed')
    } catch (err) {
      toast.error('Failed to generate prompt')
    }
  }

  // Update the table row click handler
  const handleRowClick = (prompt) => {
    handlePromptSelect(prompt)
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-gray-600">Loading prompts...</div>
      </div>
    )
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-semibold text-gray-900">Prompts</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all your custom prompts for the AI assistant
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                onClick={() => setIsCreateModalOpen(true)}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
              >
                New Prompt
              </button>
            </div>
          </div>

          <div className="mt-8 flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Content
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {prompts.map((prompt) => (
                        <tr
                          key={prompt.id}
                          onClick={() => {
                            handleRowClick(prompt)
                          }}
                          className="cursor-pointer hover:bg-gray-50 transition-colors duration-200"
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {prompt.name}
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-500">
                            <div className="max-w-xl truncate">
                              {prompt.content}
                            </div>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              onClick={(e) => {
                                e.stopPropagation() // Prevent row click
                                setSelectedPrompt(prompt)
                                setIsDeleteModalOpen(true)
                              }}
                              className="text-gray-400 hover:text-red-600 transition-colors duration-200"
                              title="Delete prompt"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Create Modal */}
          <Transition appear show={isCreateModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => setIsCreateModalOpen(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                      <div className="flex justify-between items-start">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Create New Prompt
                        </Dialog.Title>
                        <button
                          onClick={() => handlePromptGeneration()}
                          className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-gray-800 bg-purple-300 hover:bg-purple-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                          <FaMagic className="mr-1" /> Refine Prompt
                        </button>
                      </div>
                      <form onSubmit={handleCreatePrompt} className="mt-6">
                        <div className="space-y-6">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Name
                            </label>
                            <input
                              type="text"
                              value={newPrompt.name}
                              onChange={(e) =>
                                setNewPrompt({
                                  ...newPrompt,
                                  name: e.target.value,
                                })
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              required
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Content
                            </label>
                            <TextAreaWithCount
                              value={newPrompt.content}
                              onChange={(e) =>
                                setNewPrompt({
                                  ...newPrompt,
                                  content: e.target.value,
                                })
                              }
                              rows={32}
                            />
                          </div>
                        </div>
                        <div className="mt-8 flex justify-end space-x-3">
                          <button
                            type="button"
                            onClick={() => setIsCreateModalOpen(false)}
                            className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                          >
                            Create
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          {/* Edit Modal */}
          <Transition appear show={isEditModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => setIsEditModalOpen(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel
                      className="w-full max-w-7xl transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all"
                      style={{ maxHeight: '90vh' }}
                    >
                      <div className="flex justify-between items-start">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Edit Prompt
                        </Dialog.Title>
                        <div className="flex space-x-2">
                          {selectedVersion &&
                            !selectedVersion.is_production && (
                              <button
                                onClick={handleSetProduction}
                                className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-gray-800 bg-green-300 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                              >
                                🚀 Promote to Production
                              </button>
                            )}
                          <button
                            onClick={handlePromptGeneration}
                            className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-gray-800 bg-purple-300 hover:bg-purple-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                          >
                            <FaMagic className="mr-1" /> Refine Prompt
                          </button>
                        </div>
                      </div>
                      {selectedPrompt && (
                        <div
                          className="mt-6 flex"
                          style={{ maxHeight: 'calc(90vh - 120px)' }}
                        >
                          <form
                            onSubmit={handleUpdatePrompt}
                            className="flex-1 mr-6 overflow-y-auto"
                          >
                            <div className="space-y-6">
                              <div>
                                <label className="block text-sm font-medium text-gray-700">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  value={selectedPrompt.name}
                                  onChange={(e) =>
                                    setSelectedPrompt({
                                      ...selectedPrompt,
                                      name: e.target.value,
                                    })
                                  }
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  required
                                />
                              </div>
                              <div>
                                <label className="block text-sm font-medium text-gray-700">
                                  Content
                                </label>
                                <TextAreaWithCount
                                  value={selectedPrompt.content}
                                  onChange={(e) =>
                                    setSelectedPrompt({
                                      ...selectedPrompt,
                                      content: e.target.value,
                                    })
                                  }
                                  rows={32}
                                />
                              </div>
                            </div>
                            <div className="mt-8 flex justify-end space-x-3">
                              <button
                                type="button"
                                onClick={() => setIsEditModalOpen(false)}
                                className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                              >
                                Save
                              </button>
                            </div>
                          </form>
                          <VersionSelector
                            versions={promptVersions}
                            currentVersion={selectedVersion}
                            onVersionSelect={handleVersionSelect}
                          />
                        </div>
                      )}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          {/* Delete Confirmation Modal */}
          <Transition appear show={isDeleteModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => setIsDeleteModalOpen(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Delete Prompt
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this prompt? This
                          action cannot be undone.
                        </p>
                      </div>
                      <div className="mt-6 flex justify-end space-x-3">
                        <button
                          type="button"
                          onClick={() => setIsDeleteModalOpen(false)}
                          className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={handleDeletePrompt}
                          className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Delete
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
    </div>
  )
}

export default DashboardPrompts
