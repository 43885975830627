import React from 'react'
import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { CHART_COLORS, CHART_STYLES } from '../constants/chartStyles'

const LineChart = ({ data, config }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <RechartsLineChart data={data} className="transition-transform duration-300">
        <defs>
          <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={CHART_COLORS.primary} stopOpacity={0.2} />
            <stop offset="95%" stopColor={CHART_COLORS.primary} stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid stroke={CHART_STYLES.gridColor} strokeDasharray="3 3" />
        <XAxis
          dataKey={config.xAxis}
          tick={{ fill: CHART_COLORS.neutral }}
          axisLine={{ stroke: CHART_STYLES.gridColor }}
        />
        <YAxis
          tick={{ fill: CHART_COLORS.neutral }}
          axisLine={{ stroke: CHART_STYLES.gridColor }}
        />
        <Tooltip
          contentStyle={{
            backgroundColor: CHART_STYLES.tooltipBackground,
            borderRadius: '0.5rem',
            border: 'none',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
          }}
        />
        <Legend />
        <Line
          type="monotone"
          dataKey={config.yAxis}
          stroke={CHART_COLORS.primary}
          strokeWidth={2}
          dot={{ fill: CHART_COLORS.primary, strokeWidth: 2 }}
          activeDot={{ r: 6, fill: CHART_COLORS.primary }}
          fillOpacity={1}
          fill="url(#lineGradient)"
        />
      </RechartsLineChart>
    </ResponsiveContainer>
  )
}

export default LineChart 