// Define all application routes and their metadata
export const APP_ROUTES = {
  landingPage: {
    path: '/',
    title: 'Landing Page',
  },
  blog: {
    path: '/blog',
    title: 'Blog',
  },
  dashboard: {
    path: '/dashboard',
    title: 'Dashboard',
  },
  grid: {
    path: '/dashboard/grid',
    title: 'Research Grid',
  },
  canvas: {
    path: '/dashboard/canvas',
    title: 'Dynamic Canvas',
  },
  vision: {
    path: '/dashboard/vision',
    title: 'Vision',
  },
  prompts: {
    path: '/dashboard/prompts',
    title: 'Prompts',
  },
  conversations: {
    path: '/dashboard/conversations',
    title: 'Conversations',
  },
  hotkeys: {
    path: '/dashboard/hotkeys',
    title: 'Hotkeys',
  },
  settings: {
    path: '/dashboard/settings',
    title: 'Settings',
  },
  research: {
    path: '/dashboard/research',
    title: 'Research Papers',
  },
}

// Helper to get all valid paths
export const getValidPaths = () => {
  return Object.values(APP_ROUTES).map((route) => route.path)
}

// Helper to check if a path is valid
export const isValidPath = (path) => {
  const validPaths = getValidPaths()
  return validPaths.includes(path)
}

// Helper to get path and title for a route
export const getRoutes = () => {
  return APP_ROUTES
}
