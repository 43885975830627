export const CHART_COLORS = {
  primary: '#6366f1',
  secondary: '#a855f7',
  accent: '#ec4899',
  neutral: '#6b7280',
  background: '#ffffff',
  gradient: ['#6366f1', '#a855f7', '#ec4899'],
}

export const CHART_STYLES = {
  gridColor: '#f3f4f6',
  tooltipBackground: 'rgba(255, 255, 255, 0.95)',
  fontFamily: 'Inter, system-ui, sans-serif',
} 