import React from 'react'

const HotKeyRow = ({ shortcut, description }) => (
  <div className="flex items-center justify-between py-4 border-b border-gray-200 last:border-b-0">
    <span className="text-gray-700">{description}</span>
    <div className="flex items-center space-x-2">
      {shortcut.split('+').map((key, index) => (
        <React.Fragment key={index}>
          <kbd className="px-2 py-1.5 text-xs font-semibold text-gray-700 bg-gray-100 border border-gray-300 rounded-md shadow-sm">
            {key.trim()}
          </kbd>
          {index < shortcut.split('+').length - 1 && (
            <span className="text-gray-500">+</span>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
)

const DashboardHotKeys = () => {
  const hotkeys = [
    {
      shortcut: '⌘ + K',
      description: 'Toggle chat sidebar',
    },
    {
      shortcut: '⌘ + I',
      description: 'Toggle chat window mode',
    },
    {
      shortcut: '⌘ + J',
      description: 'Start new conversation',
    },
    {
      shortcut: 'ESC',
      description: 'Close chat window',
    },
  ]

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Keyboard Shortcuts</h2>
        <p className="mt-1 text-sm text-gray-500">
          Keyboard shortcuts available in the dashboard
        </p>
      </div>

      <div className="bg-white rounded-lg shadow divide-y divide-gray-200">
        <div className="p-6">
          <div className="flex items-start justify-between">
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                Available Shortcuts
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Use these keyboard shortcuts to quickly navigate and control the
                application
              </p>
            </div>
          </div>

          <div className="mt-6">
            <div className="space-y-0">
              {hotkeys.map((hotkey, index) => (
                <HotKeyRow
                  key={index}
                  shortcut={hotkey.shortcut}
                  description={hotkey.description}
                />
              ))}
            </div>

            <div className="mt-6 p-4 bg-gray-50 rounded-md border border-gray-200">
              <p className="text-sm text-gray-500">
                <span className="font-medium text-gray-700">Note:</span> On
                Windows and Linux, use{' '}
                <kbd className="px-2 py-1 text-xs font-semibold text-gray-700 bg-gray-100 border border-gray-300 rounded-md shadow-sm">
                  Ctrl
                </kbd>{' '}
                instead of{' '}
                <kbd className="px-2 py-1 text-xs font-semibold text-gray-700 bg-gray-100 border border-gray-300 rounded-md shadow-sm">
                  ⌘
                </kbd>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardHotKeys
