import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import { getAuth } from 'firebase/auth'
import { AgGridReact } from 'ag-grid-react'
import { motion } from 'framer-motion'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import '../../styles/ag-grid-custom.css'
import { API_ENDPOINTS } from '../../config'
import toast from 'react-hot-toast'
import { FaAirbnb, FaBitcoin, FaTable, FaHistory } from 'react-icons/fa'
import { CiTextAlignLeft } from 'react-icons/ci'
import { SiDoordash, SiUber, SiSlack, SiNetflix, SiMeta } from 'react-icons/si'
import { TbNumbers } from 'react-icons/tb'
import { LuListTodo } from 'react-icons/lu'
import { BsGrid3X3Gap } from 'react-icons/bs'

// Column type options
const COLUMN_TYPES = {
  STRING: 'str',
  MULTI_SELECT: 'multi-select',
  NUMBER: 'number',
}

// Multi-select options for demonstration
const MULTI_SELECT_OPTIONS = {
  'Target Markets': ['B2B', 'B2C', 'Enterprise', 'Consumer', 'SMB'],
  'Employee Count': [
    '1-50',
    '51-200',
    '201-500',
    '501-1000',
    '1001-5000',
    '5000+',
  ],
}

// Add this constant at the top of the file, after COLUMN_TYPES
const TYPE_EMOJIS = {
  [COLUMN_TYPES.STRING]: <CiTextAlignLeft />,
  [COLUMN_TYPES.MULTI_SELECT]: <LuListTodo />,
  [COLUMN_TYPES.NUMBER]: <TbNumbers />,
}

// Add this constant after your other constants
const COMPANY_ICONS = {
  Airbnb: FaAirbnb,
  Coinbase: FaBitcoin,
  DoorDash: SiDoordash,
  Uber: SiUber,
  Slack: SiSlack,
  Netflix: SiNetflix,
  Meta: SiMeta,
  // Add more mappings as needed
}

// Add these constants at the top with other constants
const STORAGE_KEYS = {
  ROW_HISTORY: 'grid_row_history',
  COLUMN_HISTORY: 'grid_column_history',
}

// Add this utility function
const getCompanyIcon = (companyName) => {
  const IconComponent = COMPANY_ICONS[companyName]
  if (IconComponent) {
    return <IconComponent className="w-5 h-5 text-gray-500" />
  }
  // Default icon for unknown companies
  return (
    <div className="w-5 h-5 rounded-full bg-gray-200 flex items-center justify-center">
      {companyName.charAt(0)}
    </div>
  )
}

// Add this new component for the company cell renderer
const CompanyRenderer = (props) => {
  return (
    <div className="flex items-center gap-2">
      {getCompanyIcon(props.value)}
      <span>{props.value}</span>
    </div>
  )
}

// Add this new component for the multi-select cell renderer
const MultiSelectRenderer = (props) => {
  if (!props.value) return null
  
  // Convert value to string and handle non-string values
  const valueStr = String(props.value)
  const values = valueStr.split(',').map(v => v.trim())
  
  return (
    <div className="flex flex-wrap gap-1">
      {values.map((v, i) => (
        <span
          key={i}
          className="inline-flex items-center px-2 py-[2px] rounded text-xs font-medium bg-blue-100 text-blue-800 leading-tight"
        >
          {v}
        </span>
      ))}
    </div>
  )
}

// Add the variants for the DynamicIsland animations
const variants = {
  expanded: {
    width: '360px',
    height: '50px',
    borderRadius: '25px',
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  },
  configure: {
    width: '400px',
    height: '300px',
    borderRadius: '20px',
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  },
  history: {
    width: '500px',
    height: '400px',
    borderRadius: '20px',
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  }
}

// Add this utility function
const getStoredHistory = (key) => {
  try {
    const stored = localStorage.getItem(key)
    return stored ? JSON.parse(stored) : []
  } catch (e) {
    console.error('Error loading history:', e)
    return []
  }
}

// Add this at the top with other constants
const PHASE_MESSAGES = {
  research: "Researching...",
  answering: "Answering..."
};

// Update the LoadingCellRenderer
const LoadingCellRenderer = ({ taskProgress }) => {
  const message = taskProgress?.phase ? PHASE_MESSAGES[taskProgress.phase] : "Loading..."
  return (
    <div className="flex items-center justify-center w-full h-full gap-2">
      <motion.div
        className="w-4 h-4 border-2 border-yellow-400 border-t-transparent rounded-full"
        animate={{ rotate: 360 }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: 'linear',
        }}
      />
      <span className="text-sm text-gray-500">{message}</span>
    </div>
  )
}

// Add the DynamicIsland component
const DynamicIsland = ({ 
  rowInput,
  setRowInput,
  columnInput,
  setColumnInput,
  onSubmit,
  activeView,
  setActiveView 
}) => {
  const islandRef = useRef(null)
  const [rowHistory, setRowHistory] = useState(() => getStoredHistory(STORAGE_KEYS.ROW_HISTORY))
  const [columnHistory, setColumnHistory] = useState(() => getStoredHistory(STORAGE_KEYS.COLUMN_HISTORY))
  const [showRowSuggestions, setShowRowSuggestions] = useState(false)
  const [showColumnSuggestions, setShowColumnSuggestions] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (islandRef.current && !islandRef.current.contains(event.target)) {
        setActiveView(null)
        setShowRowSuggestions(false)
        setShowColumnSuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [setActiveView])

  const handleFormSubmit = (e) => {
    e.preventDefault()
    
    // Save to history
    if (rowInput.trim()) {
      const newRowHistory = Array.from(new Set([rowInput, ...rowHistory])).slice(0, 5)
      setRowHistory(newRowHistory)
      localStorage.setItem(STORAGE_KEYS.ROW_HISTORY, JSON.stringify(newRowHistory))
    }
    
    if (columnInput.trim()) {
      const newColumnHistory = Array.from(new Set([columnInput, ...columnHistory])).slice(0, 5)
      setColumnHistory(newColumnHistory)
      localStorage.setItem(STORAGE_KEYS.COLUMN_HISTORY, JSON.stringify(newColumnHistory))
    }

    onSubmit(e)
    setActiveView(null)
  }

  const currentVariant = activeView === 'configure'
    ? 'configure'
    : activeView === 'history'
      ? 'history'
      : 'expanded'

  return (
    <motion.div
      ref={islandRef}
      initial="expanded"
      animate={currentVariant}
      variants={variants}
      className="fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50"
    >
      <motion.div 
        className="w-full h-full overflow-hidden relative"
        style={{ 
          borderRadius: 'inherit',
          backgroundColor: 'rgba(75, 85, 99, 0.80)',
          backdropFilter: 'blur-lg',
        }}
      >
        <motion.div className="absolute inset-0 flex flex-col" layout>
          {/* Configure View */}
          {activeView === 'configure' && (
            <motion.div 
              className="px-6 pt-6 pb-[60px] flex-shrink-0"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <form onSubmit={handleFormSubmit} className="space-y-6">
                <div className="relative">
                  <label className="block text-sm font-medium text-white mb-1">
                    Row Items
                  </label>
                  <input
                    type="text"
                    value={rowInput}
                    onChange={(e) => setRowInput(e.target.value)}
                    onFocus={() => setShowRowSuggestions(true)}
                    placeholder="e.g., Airbnb, Coinbase, DoorDash"
                    className="w-full px-4 py-2 rounded-lg bg-white 
                      text-gray-700 placeholder-gray-400
                      border border-gray-300 shadow-sm
                      focus:border-gray-400 focus:ring-0 text-sm"
                  />
                  {showRowSuggestions && rowHistory.length > 0 && (
                    <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200">
                      {rowHistory.map((item, index) => (
                        <button
                          key={index}
                          type="button"
                          onClick={() => {
                            setRowInput(item)
                            setShowRowSuggestions(false)
                          }}
                          className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50 first:rounded-t-lg last:rounded-b-lg"
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  )}
                </div>

                <div className="relative">
                  <label className="block text-sm font-medium text-white mb-1">
                    Column Items
                  </label>
                  <input
                    type="text"
                    value={columnInput}
                    onChange={(e) => setColumnInput(e.target.value)}
                    onFocus={() => setShowColumnSuggestions(true)}
                    placeholder="e.g., Founders, Target Markets, Employee Count"
                    className="w-full px-4 py-2 rounded-lg bg-white 
                      text-gray-700 placeholder-gray-400
                      border border-gray-300 shadow-sm
                      focus:border-gray-400 focus:ring-0 text-sm"
                  />
                  {showColumnSuggestions && columnHistory.length > 0 && (
                    <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200">
                      {columnHistory.map((item, index) => (
                        <button
                          key={index}
                          type="button"
                          onClick={() => {
                            setColumnInput(item)
                            setShowColumnSuggestions(false)
                          }}
                          className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50 first:rounded-t-lg last:rounded-b-lg"
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-white text-gray-700 rounded-lg
                    border border-gray-300 shadow-sm
                    hover:bg-gray-50 transition-colors duration-100
                    disabled:opacity-50 disabled:cursor-not-allowed
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                    flex items-center justify-center space-x-2"
                  disabled={!rowInput || !columnInput}
                >
                  <BsGrid3X3Gap className="w-4 h-4 text-gray-500" />
                  <span>Generate Grid</span>
                </button>
              </form>
            </motion.div>
          )}

          {/* Buttons Row */}
          <div className="absolute bottom-0 left-1/2 w-[360px] h-[43px] -translate-x-1/2">
            <motion.button
              className={`absolute left-6 flex items-center space-x-2 px-4 py-2 rounded-lg
                font-medium transition-all duration-100
                ${activeView === 'configure' 
                  ? 'bg-white/20 text-white'
                  : 'text-gray-200 hover:bg-white/20 hover:text-white'}`}
              onClick={() => setActiveView(activeView === 'configure' ? null : 'configure')}
              whileTap={{ scale: 0.98 }}
            >
              <FaTable className="w-4 h-4" />
              <span className="text-sm">Configure Grid</span>
            </motion.button>

            <motion.button
              className={`absolute right-6 flex items-center space-x-2 px-4 py-2 rounded-lg
                font-medium transition-all duration-100
                ${activeView === 'history' 
                  ? 'bg-white/20 text-white'
                  : 'text-gray-200 hover:bg-white/20 hover:text-white'}`}
              onClick={() => setActiveView(activeView === 'history' ? null : 'history')}
              whileTap={{ scale: 0.98 }}
            >
              <FaHistory className="w-4 h-4" />
              <span className="text-sm">Grid History</span>
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

// Update the detectColumnTypes function to use Firebase auth
const detectColumnTypes = async (rows, columns) => {
  try {
    const auth = getAuth()
    const token = await auth.currentUser?.getIdToken()

    const response = await fetch(`${API_ENDPOINTS.GRID}/detect-types`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        rows: rows,
        columns: columns,
      }),
    });

    if (!response.ok) {
      if (response.status === 403) {
        toast.error('Authentication failed. Please sign in again.');
        return null;
      }
      throw new Error('Failed to detect column types');
    }

    const columnTypes = await response.json();
    return columnTypes;
  } catch (error) {
    toast.error('Failed to detect column types');
    console.error('Error detecting column types:', error);
    return null;
  }
};

const DashboardGrid = () => {
  const [loading, setLoading] = useState(false)
  const [cellsLoading, setCellsLoading] = useState(false)
  const [rowInput, setRowInput] = useState('')
  const [columnInput, setColumnInput] = useState('')
  const [gridData, setGridData] = useState(null)
  const [editingCell, setEditingCell] = useState(null)
  const [columnTypes, setColumnTypes] = useState({})
  const [activeView, setActiveView] = useState(null)
  const [taskProgress, setTaskProgress] = useState(null)

  // Add these new state variables for AG Grid
  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)

  // AG Grid column definitions
  const createColumnDefs = useCallback((columns, columnTypes) => {
    if (!columns) return []

    return [
      {
        headerName: 'Items',
        field: 'company',
        cellRenderer: CompanyRenderer,
        minWidth: 150,
        pinned: 'left',
      },
      ...columns.map(column => ({
        headerName: column,
        field: column,
        editable: true,
        cellRenderer: (params) => {
          if (cellsLoading) {
            return <LoadingCellRenderer taskProgress={taskProgress} />
          }
          if (columnTypes[column] === COLUMN_TYPES.MULTI_SELECT) {
            return <MultiSelectRenderer {...params} />
          }
          return params.value
        },
        cellEditor: columnTypes[column] === COLUMN_TYPES.MULTI_SELECT ? 'agSelectCellEditor' : null,
        cellEditorParams: columnTypes[column] === COLUMN_TYPES.MULTI_SELECT ? {
          values: MULTI_SELECT_OPTIONS[column] || [],
        } : undefined,
        type: columnTypes[column] === COLUMN_TYPES.NUMBER ? 'numericColumn' : undefined,
        headerComponent: 'customHeader',
        headerComponentParams: {
          icon: TYPE_EMOJIS[columnTypes[column]],
        },
      })),
    ]
  }, [cellsLoading, columnTypes, taskProgress])

  // AG Grid default column definitions
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    minWidth: 150,
    cellStyle: { display: 'flex', alignItems: 'center' },
  }), [])

  // Transform grid data for AG Grid
  const createRowData = useCallback((gridData) => {
    if (!gridData) return []

    return gridData.rows.map((row, rowIndex) => {
      const rowData = { company: row }
      gridData.columns.forEach((column, colIndex) => {
        rowData[column] = gridData.data[rowIndex][colIndex]
      })
      return rowData
    })
  }, [])

  // Handle grid ready event
  const onGridReady = useCallback((params) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }, [])

  // Update handleGenerateGrid to use the detected types
  const handleGenerateGrid = async (e) => {
    e.preventDefault();

    try {
      // Show initial loading toast
      toast.loading('Generating grid...', {
        id: 'grid-generation-toast',
        duration: Infinity,
        icon: (
          <motion.div
            className="w-4 h-4 border-2 border-white border-t-violet-400 rounded-full"
            animate={{ rotate: 360 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: 'linear',
              repeatType: 'loop',
            }}
          />
        ),
      });

      // Parse inputs into arrays
      const rows = rowInput.split(',').map((item) => item.trim());
      const columns = columnInput.split(',').map((item) => item.trim());

      // Detect column types
      const detectedTypes = await detectColumnTypes(rows, columns);
      if (!detectedTypes) {
        toast.dismiss('grid-generation-toast');
        return;
      }

      // Initialize column types based on detection
      const initialColumnTypes = {};
      detectedTypes.forEach((col) => {
        initialColumnTypes[col.column_name] = col.type;
      });
      setColumnTypes(initialColumnTypes);

      // Create empty grid data
      const emptyData = rows.map(() => columns.map(() => ''));

      setGridData({
        rows,
        columns,
        data: emptyData,
      });

      // Show success toast and dismiss loading toast
      toast.dismiss('grid-generation-toast');
      toast.success('Grid generated successfully');

    } catch (err) {
      toast.dismiss('grid-generation-toast');
      toast.error('Failed to generate grid');
    }
  };

  // Update handlePopulateGrid to use Firebase auth and handle task polling
  const handlePopulateGrid = async () => {
    if (!gridData) return

    setLoading(true)
    setCellsLoading(true)
    let pollInterval

    try {
      const auth = getAuth()
      const token = await auth.currentUser?.getIdToken()

      // Start the grid population task
      const response = await fetch(
        `${API_ENDPOINTS.GRID}/populate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            rows: gridData.rows,
            columns: gridData.columns,
          }),
        }
      )

      if (!response.ok) {
        if (response.status === 403) {
          toast.dismiss('grid-population-toast')
          toast.error('Authentication failed. Please sign in again.')
          return
        }
        throw new Error('Failed to start grid population')
      }

      const { task_id } = await response.json()
      
      // Function to poll the task status
      const pollStatus = async () => {
        try {
          const token = await auth.currentUser?.getIdToken()
          const statusResponse = await fetch(
            `${API_ENDPOINTS.GRID}/populate/${task_id}/status`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            }
          )

          if (!statusResponse.ok) {
            throw new Error('Failed to fetch status')
          }

          const status = await statusResponse.json()
          
          // Update task progress from status
          if (status.progress) {
            setTaskProgress(JSON.parse(status.progress))
          }

          // Check if task is completed
          if (status.status === 'completed') {
            // Update grid with the result
            if (status.result) {
              setGridData({
                rows: status.result.rows,
                columns: status.result.columns,
                data: status.result.data,
              })
            }

            // Clear polling and states
            clearInterval(pollInterval)
            setCellsLoading(false)
            setLoading(false)
            toast.dismiss('grid-population-toast')
            toast.success('Grid populated successfully')
            setTaskProgress(null)  // Clear progress on completion
            return
          }

          // Handle failed status
          if (status.status === 'failed') {
            clearInterval(pollInterval)
            setCellsLoading(false)
            setLoading(false)
            toast.dismiss('grid-population-toast')
            toast.error(`Population failed: ${status.error || 'Unknown error'}`)
            setTaskProgress(null)  // Clear progress on error
            return
          }

        } catch (error) {
          console.error('Error polling status:', error)
          clearInterval(pollInterval)
          setCellsLoading(false)
          setLoading(false)
          toast.dismiss('grid-population-toast')
          toast.error('Failed to get population status')
          setTaskProgress(null)  // Clear progress on error
        }
      }

      // Start polling
      pollInterval = setInterval(pollStatus, 2000)
      pollStatus() // Initial poll

    } catch (err) {
      toast.dismiss('grid-population-toast')
      toast.error(err.message)
      // Don't reset loading states here, they'll be reset when polling completes
      if (pollInterval) {
        clearInterval(pollInterval)
      }
    }
  }

  // Update the handleCellEdit function
  const handleCellEdit = useCallback((params) => {
    const { data, colDef, newValue } = params
    const columnType = columnTypes[colDef.field]

    if (columnType === COLUMN_TYPES.NUMBER && isNaN(newValue) && newValue !== '') {
      toast.error('Please enter a valid number')
      return false
    }

    return true
  }, [columnTypes])

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1 overflow-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* Header */}
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900">Research Grid</h2>
            <p className="mt-1 text-sm text-gray-500">
              Automate manual research and compare with AI to obtain a
              comprehensive overview and actionable insights
            </p>
          </div>

          {/* Grid Table */}
          {gridData && (
            <div className="space-y-8 mb-8">
              <div className="bg-white rounded-lg shadow">
                <div 
                  className="ag-theme-alpine"
                  style={{ 
                    height: `${(gridData.rows.length + 1) * 48 + 3}px`,
                    width: '100%',
                    minHeight: '150px',
                    maxHeight: '500px',
                  }}
                >
                  <AgGridReact
                    columnDefs={createColumnDefs(gridData.columns, columnTypes)}
                    rowData={createRowData(gridData)}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onCellValueChanged={handleCellEdit}
                    animateRows={true}
                    enableCellChangeFlash={true}
                    suppressMovableColumns={true}
                    className="rounded-lg"
                    headerHeight={48}
                    rowHeight={48}
                    suppressHorizontalScroll={false}
                    suppressVerticalScroll={true} // Prevent vertical scrolling
                    domLayout="normal"
                  />
                </div>
              </div>

              {/* Research Button */}
              <div className="flex justify-center">
                <button
                  onClick={handlePopulateGrid}
                  id="research-ai-btn"
                  disabled={loading}
                  className={`inline-flex items-center px-6 py-2.5 border border-gray-300 
                    text-sm font-medium rounded-md shadow-sm
                    ${loading ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'} 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 
                    transition-all duration-200`}
                >
                  {loading ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Researching...
                    </>
                  ) : (
                    <>
                      <svg
                        className="mr-2 h-5 w-5 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                      </svg>
                      Research with AI
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Add DynamicIsland */}
      <DynamicIsland
        rowInput={rowInput}
        setRowInput={setRowInput}
        columnInput={columnInput}
        setColumnInput={setColumnInput}
        onSubmit={handleGenerateGrid}
        activeView={activeView}
        setActiveView={setActiveView}
      />
    </div>
  )
}

export default DashboardGrid
