import React from 'react'

const DashboardOverview = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">
            Dashboard Overview
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Welcome to your dashboard
          </p>
        </div>
      </div>
    </div>
  )
}

export default DashboardOverview
