import React from 'react'
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { CHART_COLORS, CHART_STYLES } from '../constants/chartStyles'

const BubbleChart = ({ data, config }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <ScatterChart>
        <CartesianGrid strokeDasharray="3 3" stroke={CHART_STYLES.gridColor} />
        <XAxis
          dataKey={config.xAxis}
          type="number"
          tick={{ fill: CHART_COLORS.neutral }}
          axisLine={{ stroke: CHART_STYLES.gridColor }}
        />
        <YAxis
          dataKey={config.yAxis}
          type="number"
          tick={{ fill: CHART_COLORS.neutral }}
          axisLine={{ stroke: CHART_STYLES.gridColor }}
        />
        <ZAxis
          dataKey={config.zAxis}
          range={[50, 400]}
          name={config.name}
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          contentStyle={{
            backgroundColor: CHART_STYLES.tooltipBackground,
            borderRadius: '0.5rem',
            border: 'none',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
          }}
        />
        <Legend />
        <Scatter
          name={config.name}
          data={data}
          fill={CHART_COLORS.primary}
          fillOpacity={0.6}
        />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export default BubbleChart 