import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useState, useEffect } from 'react'
import { Toaster } from 'react-hot-toast'

import './firebase/config'

import MainLayout from './components/Layout/MainLayout'
import About from './components/LandingPage/About'
import Skills from './components/LandingPage/Skills'
import MiniProjects from './components/LandingPage/MiniProjects'
import Timeline from './components/LandingPage/Timeline'
import Author from './components/LandingPage/Author'

import Login from './components/Login/Login'
import Dashboard from './components/Dashboard/Dashboard'
import DashboardOverview from './components/Dashboard/DashboardOverview'
import DashboardSettings from './components/Dashboard/DashboardSettings'
import DashboardHotKeys from './components/Dashboard/DashboardHotKeys'
import DashboardGrid from './components/Dashboard/DashboardGrid'
import DashboardCanvas from './components/Dashboard/DashboardCanvas'
import DashboardVision from './components/Dashboard/DashboardVision'
import DashboardResearch from './components/Dashboard/DashboardResearch'
import DashboardPrompts from './components/Dashboard/DashboardPrompts'
import DashboardConversations from './components/Dashboard/DashboardConversations'

import BlogPosts from './components/Blog/BlogPosts'
import BlogPost from './components/Blog/BlogPost'

// Create LandingPage component that combines all landing page sections
const LandingPage = () => {
  return (
    <div className="landing-page">
      <About />
      <Skills />
      <MiniProjects />
      <Timeline />
      <Author />
    </div>
  )
}

// Protected Route wrapper component
const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        Loading...
      </div>
    )
  }

  if (!user) {
    return <Navigate to="/login" />
  }

  return children
}

function App() {
  return (
    <Router>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 4000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 3000,
            theme: {
              primary: '#4aed88',
            },
          },
          error: {
            duration: 4000,
            theme: {
              primary: '#ff4b4b',
            },
          },
        }}
      />
      <MainLayout>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/blog" element={<BlogPosts />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/login" element={<Login />} />

          {/* Protected Routes - Update this section */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route index element={<DashboardOverview />} />
            <Route path="grid" element={<DashboardGrid />} />
            <Route path="canvas" element={<DashboardCanvas />} />
            <Route path="vision" element={<DashboardVision />} />
            <Route path="research" element={<DashboardResearch />} />
            <Route path="prompts" element={<DashboardPrompts />} />
            <Route path="hotkeys" element={<DashboardHotKeys />} />
            <Route path="settings" element={<DashboardSettings />} />
            <Route path="conversations" element={<DashboardConversations />} />
          </Route>
        </Routes>
      </MainLayout>
    </Router>
  )
}

export default App
