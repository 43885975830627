import React from 'react'
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { CHART_COLORS, CHART_STYLES } from '../constants/chartStyles'

const BarChart = ({ data, config }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <RechartsBarChart data={data}>
        <defs>
          <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={CHART_COLORS.primary} stopOpacity={0.8} />
            <stop offset="95%" stopColor={CHART_COLORS.secondary} stopOpacity={0.8} />
          </linearGradient>
        </defs>
        <CartesianGrid stroke={CHART_STYLES.gridColor} strokeDasharray="3 3" />
        <XAxis 
          dataKey={config.xAxis} 
          tick={{ fill: CHART_COLORS.neutral }}
          axisLine={{ stroke: CHART_STYLES.gridColor }}
        />
        <YAxis 
          tick={{ fill: CHART_COLORS.neutral }}
          axisLine={{ stroke: CHART_STYLES.gridColor }}
        />
        <Tooltip
          cursor={{ fill: 'rgba(99, 102, 241, 0.1)' }}
          contentStyle={{
            backgroundColor: CHART_STYLES.tooltipBackground,
            borderRadius: '0.5rem',
            border: 'none',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
          }}
        />
        <Legend />
        <Bar dataKey={config.yAxis} fill="url(#barGradient)" radius={[4, 4, 0, 0]} />
      </RechartsBarChart>
    </ResponsiveContainer>
  )
}

export default BarChart 