import React, { createContext, useContext, useState } from 'react'

const UIActionContext = createContext(null)

export const UIActionProvider = ({ children }) => {
  const [actionStatus, setActionStatus] = useState({
    isExecuting: false,
    lastAction: null,
    error: null,
  })

  const value = {
    actionStatus,
    setActionStatus,
  }

  return (
    <UIActionContext.Provider value={value}>
      {children}
    </UIActionContext.Provider>
  )
}

export const useUIActionContext = () => {
  const context = useContext(UIActionContext)
  if (!context) {
    throw new Error('useUIActionContext must be used within a UIActionProvider')
  }
  return context
}
