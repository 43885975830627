import { API_ENDPOINTS } from '../config'
import { getAuth } from 'firebase/auth'

const getAuthHeaders = async () => {
  const auth = getAuth()
  const user = auth.currentUser
  if (!user) throw new Error('No authenticated user found')

  const token = await user.getIdToken()

  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  }
}

export const researchService = {
  async analyzePaper(url) {
    try {
      const headers = await getAuthHeaders()
      const response = await fetch(`${API_ENDPOINTS.RESEARCH}/analyze`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ url }),
      })

      if (!response.ok) {
        throw new Error('Failed to analyze paper')
      }

      return await response.json()
    } catch (error) {
      console.error('Research service error:', error)
      throw error
    }
  },

  async getAnalysisStatus(taskId) {
    try {
      const headers = await getAuthHeaders()
      const response = await fetch(
        `${API_ENDPOINTS.RESEARCH}/analyze/${taskId}/status`,
        {
          headers,
        }
      )

      if (!response.ok) {
        throw new Error('Failed to fetch analysis status')
      }

      return await response.json()
    } catch (error) {
      console.error('Research service error:', error)
      throw error
    }
  },

  async getPastPapers() {
    try {
      const headers = await getAuthHeaders()
      const response = await fetch(`${API_ENDPOINTS.RESEARCH}/papers`, {
        headers,
      })

      if (!response.ok) {
        throw new Error('Failed to fetch past papers')
      }

      return await response.json()
    } catch (error) {
      console.error('Research service error:', error)
      throw error
    }
  },

  async getPaperById(paperId) {
    try {
      const headers = await getAuthHeaders()
      const response = await fetch(
        `${API_ENDPOINTS.RESEARCH}/papers/${paperId}`,
        {
          headers,
        }
      )

      if (!response.ok) {
        throw new Error('Failed to fetch paper analysis')
      }

      return await response.json()
    } catch (error) {
      console.error('Research service error:', error)
      throw error
    }
  },
}
