import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { loadBlogPosts } from '../../utils/blogLoader'

const BlogPosts = () => {
  const [blogs, setBlogs] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const posts = await loadBlogPosts()
        setBlogs(posts)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchBlogs()
  }, [])

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-gray-600">Loading posts...</div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-600">Error loading posts: {error}</div>
      </div>
    )
  }

  return (
    <div className="flex-grow bg-gray-100 pt-16">
      <div className="max-w-2xl mx-auto px-4 py-16">
        <h1 className="text-3xl font-serif mb-12 text-gray-900">Blog Posts</h1>
        {blogs.length === 0 ? (
          <div className="text-gray-600 text-center">No posts yet.</div>
        ) : (
          <div className="space-y-4">
            {blogs.map((blog) => (
              <article
                key={blog.id}
                className="border-b border-gray-100 pb-4 last:border-b-0"
              >
                <Link to={`/blog/${blog.slug}`}>
                  <time className="text-sm text-gray-500">
                    {new Date(blog.timestamp).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </time>
                  <h2 className="text-2xl font-serif mt-2 mb-3 text-gray-900 hover:text-gray-700">
                    {blog.title}
                  </h2>
                  <p className="text-gray-600 leading-relaxed">
                    {blog.summary}
                  </p>
                </Link>
              </article>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default BlogPosts
