const config = {
  name: 'Aaron Lim',
  full_name: 'Aaron Lim Fang Zhen',
  description: 'Senior AI Engineer',
  bio: 'Senior AI Engineer',
  location: 'Singapore',
  email: 'aaronlimfz@gmail.com',
  profile_picture: '/assets/images/profile/aaron_finland_lounge.jpg',
  cover: '/assets/images/header/mountain.jpg',
  logo: '/assets/images/logo/grainer-logo-light.png',
  short_url: 'aarongrainer.github.io',
  github_url: 'github.com/aarongrainer',
  linkedin_url: 'linkedin.com/in/aaron-lim-9376701aa/',
  twitter_url: 'twitter.com/aarongrainer',
  google_analytics: 'UA-69281367-1',
  assistant_name: 'Avery',
}

export default config

export const API_BASE_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:8000'
export const WS_BASE_URL = process.env.REACT_APP_WS_URL || 'ws://localhost:8000'

export const API_ENDPOINTS = {
  HEALTH: `${API_BASE_URL}/api/health`,
  USER_INFO: `${API_BASE_URL}/api/user/me`,
  USER_CREATE: `${API_BASE_URL}/api/user`,
  CHAT_WS: `${WS_BASE_URL}/api/ws/chat`,
  GRID: `${API_BASE_URL}/api/grid`,
  RESEARCH: `${API_BASE_URL}/api/research`,
  CONVERSATIONS: `${API_BASE_URL}/api/conversations`,
  API_KEYS: `${API_BASE_URL}/api/api-keys`,
  PROMPTS: `${API_BASE_URL}/api/prompts`,
}
