import React, { useState } from 'react'
import timelineData from '../../data/timeline.json'

function Timeline() {
  const [hoveredIndex, setHoveredIndex] = useState(null)

  return (
    <section id="timeline" className="py-16 bg-white">
      <div className="container mx-auto px-4 max-w-5xl">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Timeline</h2>
          <h4 className="text-xl text-gray-500">
            My journey up till this point
          </h4>
        </div>

        <div className="relative mx-4 md:mx-16">
          {/* Vertical line - hidden on mobile, visible on desktop */}
          <div
            className="hidden md:block absolute left-1/2 transform -translate-x-1/2 w-[2px] bg-gray-200"
            style={{
              top: '75px',
              height: 'calc(100% - 175px)',
            }}
          ></div>

          {/* Mobile vertical line */}
          <div
            className="md:hidden absolute left-[40px] w-[2px] bg-gray-200"
            style={{
              top: '100px',
              height: 'calc(100% - 225px)',
            }}
          ></div>

          <div className="relative">
            {timelineData.map((item, index) => (
              <div
                key={index}
                className="mb-24"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {/* Timeline item container */}
                <div
                  className={`relative flex items-center
                  ${index % 2 === 0 ? 'md:justify-end' : 'md:justify-start'}
                  flex-row md:flex-row
                  transition-all duration-300 ease-in-out
                  ${hoveredIndex === index ? 'scale-105' : ''}`}
                >
                  {/* Image */}
                  <div
                    className={`
                    relative z-10 
                    md:absolute md:left-1/2 md:transform md:-translate-x-1/2
                    flex-shrink-0 ml-0 md:ml-0
                    transition-all duration-300 ease-in-out
                    ${hoveredIndex === index ? 'md:scale-110' : ''}
                  `}
                  >
                    <img
                      src={item.assets}
                      alt=""
                      className="w-20 h-20 md:w-[150px] md:h-[150px] rounded-full border-[7px] border-gray-200 bg-white object-cover
                        transition-all duration-300 ease-in-out"
                    />
                  </div>

                  {/* Content panel */}
                  <div
                    className={`
                    flex-grow ml-4 md:ml-0
                    md:w-[42%] 
                    ${index % 2 === 0 ? 'md:mr-[58%]' : 'md:ml-[58%]'}
                    self-center
                  `}
                  >
                    <div
                      className={`bg-white p-4 md:p-6 rounded 
                      ${index % 2 === 0 ? 'md:text-right' : 'text-left'}
                      transition-all duration-300 ease-in-out
                      ${hoveredIndex === index ? 'shadow-lg' : 'shadow-none'}`}
                    >
                      <div className="mb-2">
                        <h4 className="text-gray-600 text-base md:text-lg font-medium">
                          {item.date}
                        </h4>
                        <h4 className="text-lg md:text-xl font-bold">
                          {item.title}
                        </h4>
                        {/* Add company text */}
                        <h5 className="text-gray-700 text-sm md:text-base font-medium">
                          {item.company}
                        </h5>
                      </div>
                      <div className="text-gray-500 text-sm md:text-base">
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Timeline
