export const sampleData = {
  content: [
    {
      type: 'visualization',
      chartType: 'map',
      title: 'Global Office Locations',
      width: 'full',
      data: [
        {
          lat: 37.7749,
          lng: -122.4194,
          title: 'San Francisco HQ',
          description: 'Main headquarters and engineering hub',
        },
        {
          lat: 40.7128,
          lng: -74.006,
          title: 'New York Office',
          description: 'East coast operations and sales',
        },
        {
          lat: 51.5074,
          lng: -0.1278,
          title: 'London Office',
          description: 'European headquarters',
        },
        {
          lat: 35.6762,
          lng: 139.6503,
          title: 'Tokyo Office',
          description: 'Asia Pacific operations',
        },
        {
          lat: -33.8688,
          lng: 151.2093,
          title: 'Sydney Office',
          description: 'Australia and NZ operations',
        },
      ],
      config: {
        center: [20, 0],
        zoom: 2,
      },
    },
    {
      type: 'summary',
      content: 'Sales have shown an upward trend with a notable peak in April.',
      width: 'full',
    },
    {
      type: 'visualization',
      chartType: 'pie',
      title: 'Revenue Distribution',
      width: 'half',
      data: [
        { name: 'Product A', value: 400 },
        { name: 'Product B', value: 300 },
        { name: 'Product C', value: 200 },
      ],
      config: {
        name: 'name',
        value: 'value',
      },
    },
    {
      type: 'visualization',
      chartType: 'bar',
      title: 'Monthly Performance',
      width: 'half',
      data: [
        { month: 'Jan', sales: 800 },
        { month: 'Feb', sales: 900 },
        { month: 'Mar', sales: 1200 },
      ],
      config: {
        xAxis: 'month',
        yAxis: 'sales',
      },
    },
    {
      type: 'visualization',
      chartType: 'line',
      title: 'Sales Trend',
      width: 'full',
      data: [
        { month: 'Jan', value: 1000 },
        { month: 'Feb', value: 1500 },
        { month: 'Mar', value: 1300 },
        { month: 'Apr', value: 1800 },
      ],
      config: {
        xAxis: 'month',
        yAxis: 'value',
      },
    },
    {
      type: 'visualization',
      chartType: 'table',
      title: 'Performance Metrics',
      width: 'full',
      data: {
        headers: ['Metric', 'Q1', 'Q2', 'Q3', 'Q4', 'YoY Growth'],
        rows: [
          ['Revenue', '$1.2M', '$1.5M', '$1.8M', '$2.1M', '+75%'],
          ['Users', '15K', '18K', '24K', '30K', '+100%'],
          ['Conversion', '2.4%', '2.8%', '3.2%', '3.8%', '+58%'],
          ['Churn Rate', '1.8%', '1.6%', '1.4%', '1.2%', '-33%'],
        ],
      },
      config: {
        striped: true,
        highlightOnHover: true,
        compact: false,
        formatters: {
          'YoY Growth': (value) => ({
            className: value.startsWith('+') ? 'text-green-600' : 'text-red-600',
            value: value,
          }),
        },
      },
    },
    {
      type: 'visualization',
      chartType: 'donut',
      title: 'Customer Segments',
      width: 'half',
      data: [
        { name: 'Enterprise', value: 450 },
        { name: 'Small Business', value: 300 },
        { name: 'Individual', value: 250 },
        { name: 'Government', value: 200 },
      ],
      config: {
        name: 'name',
        value: 'value',
      },
    },
    {
      type: 'visualization',
      chartType: 'scatter',
      title: 'Product Performance',
      width: 'half',
      data: [
        { price: 100, satisfaction: 85, sales: 200, product: 'Product A' },
        { price: 200, satisfaction: 88, sales: 150, product: 'Product B' },
        { price: 150, satisfaction: 90, sales: 300, product: 'Product C' },
        { price: 300, satisfaction: 95, sales: 100, product: 'Product D' },
        { price: 250, satisfaction: 87, sales: 250, product: 'Product E' },
      ],
      config: {
        xAxis: 'price',
        yAxis: 'satisfaction',
        zAxis: 'sales',
        name: 'Products',
      },
    },
    {
      type: 'visualization',
      chartType: 'histogram',
      title: 'Order Value Distribution',
      width: 'half',
      data: [
        { range: '0-50', count: 120 },
        { range: '51-100', count: 250 },
        { range: '101-150', count: 180 },
        { range: '151-200', count: 90 },
        { range: '201+', count: 60 },
      ],
      config: {
        xAxis: 'range',
        yAxis: 'count',
        barSize: 30,
      },
    },
    {
      type: 'visualization',
      chartType: 'boxplot',
      title: 'Monthly Sales Distribution',
      width: 'half',
      data: [
        { month: 'Jan', min: 500, max: 1500, median: 1000, q1: 750, q3: 1250 },
        { month: 'Feb', min: 600, max: 1700, median: 1200, q1: 900, q3: 1400 },
        { month: 'Mar', min: 800, max: 2000, median: 1400, q1: 1100, q3: 1600 },
        { month: 'Apr', min: 900, max: 2200, median: 1600, q1: 1300, q3: 1900 },
      ],
      config: {
        xAxis: 'month',
      },
    },
    {
      type: 'visualization',
      chartType: 'area',
      title: 'User Growth Over Time',
      width: 'half',
      data: [
        { month: 'Jan', users: 1000, activeUsers: 800 },
        { month: 'Feb', users: 1500, activeUsers: 1200 },
        { month: 'Mar', users: 2000, activeUsers: 1600 },
        { month: 'Apr', users: 2500, activeUsers: 2000 },
        { month: 'May', users: 3000, activeUsers: 2400 },
      ],
      config: {
        xAxis: 'month',
        yAxis: 'users',
      },
    },
    {
      type: 'visualization',
      chartType: 'bubble',
      title: 'Market Analysis',
      width: 'half',
      data: [
        { market: 'US', revenue: 800, growth: 20, size: 400, name: 'US Market' },
        { market: 'EU', revenue: 600, growth: 15, size: 300, name: 'EU Market' },
        { market: 'Asia', revenue: 400, growth: 30, size: 200, name: 'Asia Market' },
        { market: 'LATAM', revenue: 200, growth: 25, size: 100, name: 'LATAM Market' },
      ],
      config: {
        xAxis: 'revenue',
        yAxis: 'growth',
        zAxis: 'size',
        name: 'Markets',
        size: 'size',
      },
    },
    {
      type: 'visualization',
      chartType: 'cluster',
      title: 'Customer Segmentation',
      width: 'full',
      data: [
        { x: 82, y: 75, cluster: 'High Value', size: 120, label: 'Segment A' },
        { x: 78, y: 68, cluster: 'High Value', size: 90, label: 'Segment B' },
        { x: 84, y: 78, cluster: 'High Value', size: 110, label: 'Segment C' },
        { x: 45, y: 35, cluster: 'Low Value', size: 70, label: 'Segment D' },
        { x: 40, y: 38, cluster: 'Low Value', size: 60, label: 'Segment E' },
        { x: 35, y: 42, cluster: 'Low Value', size: 50, label: 'Segment F' },
        { x: 58, y: 55, cluster: 'Medium Value', size: 85, label: 'Segment G' },
        { x: 62, y: 52, cluster: 'Medium Value', size: 95, label: 'Segment H' },
        { x: 55, y: 48, cluster: 'Medium Value', size: 80, label: 'Segment I' },
      ],
      config: {
        xAxis: 'x',
        yAxis: 'y',
        clusterKey: 'cluster',
        sizeKey: 'size',
        labelKey: 'label',
        clusters: {
          'High Value': '#4ade80', // Green
          'Medium Value': '#facc15', // Yellow
          'Low Value': '#f87171', // Red
        },
      },
    },
  ],
} 