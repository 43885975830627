import React from 'react'

const TableChart = ({ data, config }) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-200">
          <tr>
            {data.headers.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          className={`bg-white divide-y divide-gray-200 ${
            config.striped ? 'divide-y divide-gray-200' : ''
          }`}
        >
          {data.rows.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={`${
                config.striped && rowIndex % 2 === 0 ? 'bg-gray-50' : ''
              } ${
                config.highlightOnHover
                  ? 'hover:bg-gray-50 transition-colors duration-150'
                  : ''
              }`}
            >
              {row.map((cell, cellIndex) => {
                const formatter = config.formatters?.[data.headers[cellIndex]]
                const formattedCell = formatter
                  ? formatter(cell)
                  : { value: cell }

                return (
                  <td
                    key={cellIndex}
                    className={`px-6 py-4 whitespace-nowrap text-sm ${
                      cellIndex === 0
                        ? 'font-medium text-gray-900'
                        : 'text-gray-500'
                    } ${config.compact ? 'py-2' : 'py-4'} ${
                      formattedCell.className || ''
                    }`}
                  >
                    {formattedCell.value}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableChart 