import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Sidebar from '../Dashboard/Sidebar'

const MainLayout = ({ children }) => {
  const location = useLocation()
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  // Update sidebar visibility based on route
  useEffect(() => {
    // Hide sidebar on landing page, show everywhere else
    const shouldShowSidebar = location.pathname !== '/'
    setIsSidebarVisible(shouldShowSidebar)
  }, [location.pathname])

  return (
    <div className="flex min-h-screen bg-gray-100 overflow-hidden">
      {/* Sidebar Container */}
      <div
        className={`transition-all duration-300 ease-in-out
        ${isSidebarVisible ? (isExpanded ? 'w-48' : 'w-16') : 'w-16'}`}
      >
        <Sidebar
          isVisible={isSidebarVisible}
          onToggleVisibility={setIsSidebarVisible}
          isExpanded={isExpanded}
          onExpand={setIsExpanded}
        />
      </div>

      {/* Main Content */}
      <div
        className={`flex-1 transition-all duration-300 ease-in-out overflow-hidden
        ${isSidebarVisible ? 'ml-0' : '-ml-16'}`}
      >
        {children}
      </div>
    </div>
  )
}

export default MainLayout
